import {Breadcrumbs, ErrorPopup, Title} from '@management-ui/core';
import React from 'react';
import routes from '../../../../routes';

const Wrapper = ({title, loading, crumbs, errors, children}) => {
  return (
    <>
      <Breadcrumbs crumbs={crumbs}/>
      <Title title={title} loading={loading}/>
      {children}
      <ErrorPopup errors={['notification']} messages={errors}/>
    </>
  );
}

Wrapper.standardCrumbs = [
  {title: 'Notifications', link: routes.notifications.index}
];

export default Wrapper;
