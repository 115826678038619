import {makeStyles} from '@mui/styles';

/**
 * A hook to inject form styles used within the library.
 *
 * @function useFormStyles
 *
 * @returns {object} The classes available for use with components
 *
 * @example
 * const classes = useFormStyles();
 * return <div className={classes.container}>...</div>;
 *
 */
export const useFormStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
  },

  form: {
    display: 'flex',
    position: 'relative',
  },

  panes: {
    display: 'flex',
    width: '100%'
  },

  pane: {
    flex: 1,
    flexDirection: 'column',
    padding: theme.spacing(1),
  },

  paneTitle: {
    color: theme.palette.primary.main,
    display: 'flex',
    fontSize: '1.3em',
    margin: `${theme.spacing(2)} 0 0`,
    padding: `0 ${theme.spacing(1)}`,
    position: 'relative',

    '& span': {
      backgroundColor: theme.palette.background.paper,
      padding: `0 ${theme.spacing(1)}`,
      position: 'relative',
      zIndex: 2,
    },

    '&:before': {
      backgroundColor: theme.palette.grey['200'],
      bottom: 0,
      content: '" "',
      height: 1,
      left: 0,
      margin: 'auto 0',
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: 1,
    }
  },

  loading: {
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 100,
  },

  checkbox: {
    flex: 1,
    marginLeft: 0,
    paddingTop: theme.spacing(1),
    width: '100%',
  },

  select: {
    '&.MuiFormControl-root': {
      margin: `${theme.spacing(2)} 0 ${theme.spacing(1)}`,
      width: '100%',
    },
  },

  radioGroup: {
    '&.MuiFormControl-root': {
      margin: `${theme.spacing(2)} 0 ${theme.spacing(1)}`,
      padding: `0 ${theme.spacing(1)}`,
      width: '100%',
    },
  },

  submit: {
    display: 'none',
  },

  dropzone: {
    border: `1px dashed ${theme.palette.divider}`,
    flex: 1,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    minHeight: '50px',
    padding: theme.spacing(1),
    textAlign: 'center',

    '& p': {
      margin: 0,
      padding: `${theme.spacing(2)} 0`,
    }
  },

  error: {
    color: theme.palette.error.main,
  },

  selected: {
    '& .MuiInputBase-root': {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },

    '& .MuiInputLabel-root': {
      transform: `translate(0, ${theme.spacing(2)}) scale(1)`,
    },
  },

  popper: {
    border: `1px solid ${theme.palette.grey['200']}`,
    maxHeight: 400,
    overflowY: 'scroll',
    zIndex: 2000,
  },

  noResults: {
    padding: theme.spacing(2),
  },

  google: {
    height: 'auto',
    margin: `0 0 ${theme.spacing(1)} ${theme.spacing(2)}`,
    width: 100,
  }
}));
