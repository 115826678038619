import {Paper, styled, Typography} from '@mui/material';
import {reverse} from 'named-urls';
import React, {useEffect, useMemo, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import Switched from '../../../../../components/Switched';
import Switcher from '../../../../../components/Switcher';
import routes from '../../../../../routes';
import Details from './Details';
import Parts from './Parts';

const Container = styled(Paper)(({theme}) => ({
  '&.MuiPaper-root': {
    overflow: 'hidden',
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
  },

  '& > .MuiTypography-subtitle2': {
    color: theme.palette.grey['600']
  },
}));

const Tabs = ({visit, onVisitUpdated}) => {
  const match = useRouteMatch();
  const [tab, setTab] = useState('loading');

  const tabs = useMemo(() => [
    {
      title: 'Parts',
      slug: '',
      path: reverse(routes.jobs.visit.detail, {
        id: visit?.job?.id ?? 0,
        visitID: visit?.id ?? 0
      })
    },
    {
      title: 'Report',
      slug: 'report',
      path: reverse(routes.jobs.visit.tab, {
        id: visit?.job?.id ?? 0,
        visitID: visit?.id ?? 0,
        tab: 'report'
      })
    },
  ], [visit]);

  useEffect(() => {
    if (match.params?.tab && tabs.findIndex(t => t.slug) >= 0) {
      setTab(match.params?.tab);
    } else {
      setTab('');
    }
  }, [match, tabs]);

  return (
    <>
      <Switcher tabs={tabs}/>
      <Switched tab="" currentTab={tab}>
        <Container>
          <Typography variant="subtitle2">Parts</Typography>
          <Parts visit={visit}/>
        </Container>
      </Switched>
      <Switched tab="report" currentTab={tab}>
        <Details visit={visit} onVisitUpdated={onVisitUpdated}/>
      </Switched>
    </>
  );
};

export default Tabs;
