import {Breadcrumbs, CachedTabs, useTab} from '@management-ui/core';
import {FormatListBulleted, Settings as SettingsIcon} from '@mui/icons-material';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {usePermissions} from '../../../../hooks';
import routes from '../../../../routes';
import Attributes from './Attributes';
import Settings from './Settings';

const TABS = [
  {
    id: 'settings',
    slug: 'settings',
    title: 'Settings',
    icon: <SettingsIcon/>,
    render: (props) => <Settings {...props} />,
    permissions: ['change_settings']
  },
  {
    id: 'attributes',
    slug: 'attributes',
    title: 'Attributes',
    icon: <FormatListBulleted/>,
    render: (props) => <Attributes {...props}/>,
    permissions: ['manage_attributes']
  }
];

const Tabs = ({tabs}) => {
  const {tab, handleTab} = useTab(tabs, routes.management);
  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Management'}, {title: tabs.find(t => t.id === tab).title}]}/>
      <CachedTabs tab={tab} onChange={handleTab} tabs={tabs}/>
    </div>
  );
}

const Management = () => {
  const {userCan} = usePermissions();
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    setTabs([...TABS].filter(({permissions}) => userCan(permissions, true, false)));
  }, [userCan])

  return tabs.length > 0 ? <Tabs tabs={tabs}/> : null;
};

export default Management;
