import {Breadcrumbs, FormDialog, Title} from '@management-ui/core';
import {List} from '@mui/icons-material';
import {IconButton, Tooltip} from '@mui/material';
import {makeStyles} from '@mui/styles';
import moment from 'moment';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useState} from 'react';
import {useHistory} from 'react-router-dom';
import routes from '../../../../routes';
import VisitForm from '../../forms/VisitForm';
import Calendar from './Calendar';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  }
}));

const Bookings = () => {
  const classes = useStyles();
  const history = useHistory();

  const [showForm, setShowForm] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment().startOf('day'));
  const [selectedTime, setSelectedTime] = useState('');

  const handleVisit = useCallback((visit) => history.push(reverse(routes.jobs.visit.detail, {
    id: visit.job.id,
    visitID: visit.id
  })), [history]);

  const handleDay = useCallback(({date, time = ''}) => {
    setSelectedDate(date);
    setSelectedTime(time);
    setShowForm(true);
  }, []);

  return (
    <div className={classes.container}>
      <Breadcrumbs crumbs={[{title: 'Visits'}]}/>
      <Title title="Bookings Calendar">
        <Tooltip title="View List">
          <IconButton onClick={() => history.push(routes.visits.list)}>
            <List/>
          </IconButton>
        </Tooltip>
      </Title>
      <Calendar onSelectVisit={handleVisit} onSelectDay={handleDay}/>
      <FormDialog
        title="Add New Visit"
        open={showForm}
        onClose={() => setShowForm(false)}
        render={(props) => (
          <VisitForm
            {...props}
            visit={{date: selectedDate.toISOString(), time: selectedTime, confirmed: true}}
            onSaved={handleVisit}
          />
        )}
      />
    </div>
  );
}

export default Bookings;
