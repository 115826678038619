import {FormDialog} from '@management-ui/core';
import moment from 'moment';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useState} from 'react';
import {useHistory} from 'react-router-dom';
import routes from '../../../../routes';
import VisitForm from '../../forms/VisitForm';
import Calendar from '../Visits/Calendar';
import withJob from './withJob';
import Wrapper from './Wrapper';

const NewVisit = ({job, title, crumbs, loading, errors}) => {
  const [showForm, setShowForm] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment().startOf('day'));

  const history = useHistory();
  const handleVisit = useCallback((visit) => history.push(reverse(routes.jobs.visit.detail, {
    id: visit.job.id,
    visitID: visit.id,
  })), [history]);

  const handleDay = useCallback(({date}) => {
    setSelectedDate(date);
    setShowForm(true);
  }, []);

  return (
    <Wrapper
      title={title}
      job={job}
      loading={loading}
      crumbs={crumbs.length < 2 ? crumbs : [...crumbs, {title: 'New Visit', link: ''}]}
      errors={errors}
    >
      <Calendar onSelectVisit={handleVisit} onSelectDay={handleDay}/>
      <FormDialog
        title="Add New Visit"
        open={showForm}
        onClose={() => setShowForm(false)}
        render={(props) => (
          <VisitForm
            {...props}
            visit={{date: selectedDate.toISOString(), confirmed: true}}
            job={job}
            onSaved={handleVisit}
          />
        )}
      />
    </Wrapper>
  );
}

export default withJob(NewVisit, true);
