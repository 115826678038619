import moment from 'moment';
import * as qs from 'qs';
import {API} from './API';

export class VisitService {
  async getTableVisits(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getVisits(query, page, length);
  }

  async getVisits(query, page, length) {
    return (await API.getConnection())
      .get('visits', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getVisit(id, archive = false) {
    return (await API.getConnection())
      .get(`visits/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveVisit(data) {
    let method = 'post';
    let url = 'visits';
    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    }
    if (data.date) {
      data.date = moment(data.date).toISOString(true);
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async reorderVisits(query, visits) {
    return (await API.getConnection())
      .put('visits', {visits}, {
        params: {...query, page: 1, length: 500},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async getImages(visit) {
    return (await API.getConnection())
      .get(`visits/${visit.id}/images`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async complete(visit) {
    return (await API.getConnection())
      .post(`visits/${visit.id}/complete`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async reOpen(visit) {
    return (await API.getConnection())
      .post(`visits/${visit.id}/re-open`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteVisit(visit) {
    return (await API.getConnection())
      .delete(`visits/${visit.id}`)
      .then(() => null)
      .catch(error => API.handleError(error));
  }

  async report(visit) {
    return (await API.getConnection())
      .get(`visits/${visit.id}/report`, {
        responseType: 'blob',
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.headers['x-filename']);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => API.handleError(error));
  }

  async sendConfirmation(visit) {
    return (await API.getConnection())
      .post(`visits/${visit.id}/confirm`)
      .then(() => null)
      .catch(error => API.handleError(error));
  }

  async uploadImages(visit, images) {
    const body = new FormData();
    for (let file of images) {
      body.append('images[]', file);
    }
    return (await API.getConnection())
      .post(`visits/${visit.id}/images`, body, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async downloadImage(visitID, id) {
    return (await API.getConnection())
      .get(`visits/${visitID}/images/${id}`, {responseType: 'blob'})
      .then(response => API.triggerDownload(response))
      .catch(error => API.handleError(error));
  }

  async deleteImage(visit, image) {
    return (await API.getConnection())
      .delete(`visits/${visit.id}/images/${image.id}`, {})
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async getParts(visit) {
    return (await API.getConnection())
      .get(`visits/${visit.id}/parts`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async togglePart(visit, part) {
    return (await API.getConnection())
      .post(`visits/${visit.id}/parts/${part.id}`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }
}
