import {ConfirmationDialog, FormDialog} from '@management-ui/core';
import {Add, Delete, Download} from '@mui/icons-material';
import {IconButton, Tooltip} from '@mui/material';
import * as React from 'react';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import JobFileForm from '../../forms/JobFileForm';
import useTableStyles from './tableStyles';

const JobFiles = ({job = null}) => {
  const classes = useTableStyles();
  const services = useContext(ServiceContext);
  const [showNew, setShowNew] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const initialised = useRef(false);

  useEffect(() => {
    if (job && !initialised.current) {
      initialised.current = true;
      setLoading(true);
      services.job.getFiles(job).then(list => {
        setFiles(list);
        setLoading(false);
        setLoaded(true);
      }).catch(() => {
        setLoading(false);
        setLoaded(true);
      });
    }
  }, [services, job]);

  const handleDownload = useCallback((file) => {
    services.job.downloadFile(job.id, file.id)
      .then(() => null)
      .catch(() => null);
  }, [services, job]);

  const handleDelete = useCallback((confirmed) => {
    setShowDelete(false);
    if (confirmed) {
      setLoading(true);
      services.job.deleteFile(job, selected).then(list => {
        setLoading(false);
        setFiles(list);
      }).catch(() => setLoading(false));
    }
  }, [services, job, selected]);

  return (
    <div className={[classes.container, loading ? classes.loading : ''].join(' ')}>
      {job.completed ? null : (
        <div className={classes.actions}>
          <Tooltip title="Add New File">
            <IconButton onClick={() => setShowNew(true)}>
              <Add/>
            </IconButton>
          </Tooltip>
        </div>
      )}
      <div className={classes.row}>
        <div className={classes.header}>Title</div>
        <div className={classes.header}>Filename</div>
        <div className={classes.action}/>
        {job.completed ? null : <div className={classes.action}/>}
      </div>
      {loaded ? (files.length > 0 ? (
        files.map((file, index) => (
          <div key={index} className={classes.row}>
            <div className={classes.column}>{file.title}</div>
            <div className={classes.column}>{file.name}</div>
            <div className={classes.action}>
              <Tooltip title="Download File">
                <IconButton onClick={() => handleDownload(file)}>
                  <Download/>
                </IconButton>
              </Tooltip>
            </div>
            {job.completed ? null : (
              <div className={classes.action}>
                <Tooltip title="Delete File">
                  <IconButton onClick={() => setSelected(file) || setShowDelete(true)}>
                    <Delete/>
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
        ))
      ) : (
        <div className={classes.noRecords}>There are no files associated with this job</div>
      )) : null}
      <FormDialog
        title="Add New File"
        open={showNew}
        maxWidth="sm"
        onClose={() => setShowNew(false)}
        render={(props) => (
          <JobFileForm
            {...props}
            files={[]}
            job={job}
            onSaved={(files) => setShowNew(false) || setFiles(files)}
          />
        )}
      />
      <ConfirmationDialog
        title="Delete File"
        message="Are you sure you want to delete this file?"
        open={showDelete}
        onClose={handleDelete}
      />
    </div>
  );
};

export default JobFiles;
