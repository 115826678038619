import {Checkbox, Panes, TextField} from '@management-ui/core';
import React from 'react';

const IssueFields = ({visit = {}, prefix = ''}) => (
  <Panes
    entity={visit}
    prefix={prefix}
    panes={[
      {
        title: '',
        fields: [
          <TextField
            name="findings"
            prefix={prefix}
            label="Findings"
            fieldProps={{
              multiline: true,
              rows: 5
            }}
          />,
          <TextField
            name="summary"
            prefix={prefix}
            label="Outcome"
          />,
          <TextField
            name="parts_required"
            prefix={prefix}
            label="Parts Required"
            fieldProps={{
              multiline: true,
              rows: 5
            }}
          />,
          <Checkbox
            name="follow_up"
            prefix={prefix}
            label="Follow-up Required?"
          />
        ]
      }, {
        title: '',
        fields: []
      }
    ]}
    title={''}
  />
);

export default IssueFields;
