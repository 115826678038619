import {Breadcrumbs, DataTable} from '@management-ui/core';
import {Paper} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../components/Services';
import routes from '../../../routes';
import ClientForm from '../forms/ClientForm';

const Clients = () => {
  const services = useContext(ServiceContext);
  const history = useHistory();

  const goToDetail = useCallback((client) => {
    history.push(reverse(routes.clients.detail, {id: client.id}));
  }, [history]);

  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Clients'}]}/>
      <Paper>
        <DataTable
          title="Clients"
          columns={useMemo(() => [
            {title: 'Company Name', field: 'name'},
            {title: 'Main Contact', field: 'contact'},
            {title: 'Telephone', field: 'phone'},
            {title: 'Email', field: 'email'},
          ], [])}
          loadData={query => new Promise((resolve, reject) => {
            services.client.getTableClients(query)
              .then(response => {
                resolve({
                  data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
                });
              }).catch((e) => console.log(e) || reject());
          })}
          newForm={{
            title: 'Add New Client',
            render: (props) => <ClientForm client={{}} {...props}/>,
            onSaved: goToDetail
          }}
          onRowClick={goToDetail}
        />
      </Paper>
    </div>
  );
};

export default Clients;
