import {Panes, TextField} from '@management-ui/core';
import {UploadField} from '@management-ui/upload';
import React from 'react';

const Fields = ({file = {}, prefix = ''}) => {
  return (
    <Panes
      entity={file}
      prefix={prefix}
      panes={[
        {
          title: 'File Details',
          fields: [
            <TextField
              name="title"
              prefix={prefix}
              label="Title"
              rules={{required: 'Please enter a title for this file'}}
            />,
            <UploadField
              name="file"
              prefix={prefix}
              label="Drop an file here or click to select from your computer"
              rules={{required: 'Please select an file to upload'}}
              types=".jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.csv,.xls,.xlsx"
            />
          ]
        }
      ]}
      title=""/>
  );
};

export default Fields;
