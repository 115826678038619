const routes = {
  // Access
  login: '/auth/login',
  forgotPassword: '/auth/forgot-password',
  resetPassword: '/auth/reset-password',

  // Admin
  dashboard: '/',

  clients: {
    index: '/clients',
    detail: '/clients/:id',
  },

  jobs: {
    index: '/jobs',
    detail: '/jobs/:id',
    tab: '/jobs/:id/:tab',
    newVisit: '/jobs/:id/visits/new',
    visit: {
      detail: '/jobs/:id/visits/:visitID',
      tab: '/jobs/:id/visits/:visitID/:tab'
    },
  },

  visits: {
    index: '/visits',
    list: '/visits/list'
  },

  notifications: {
    index: '/notifications',
    detail: '/notifications/:id',
  },

  users: {
    index: '/users',
    detail: '/users/:id',
  },

  management: '/management/:tab?',
};

export default routes;
