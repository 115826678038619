import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const ClientForm = forwardRef(({client, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={client}
      type="client"
      fieldsComponent={(props) => <Fields {...props}/>}
      onSave={({id, name, contact, phone, email, website, notes, store_number}) => services.client.saveClient({
        id,
        name,
        contact,
        phone,
        email,
        website,
        notes,
        store_number
      })}
      onSaved={onSaved}
    />
  );
});

export default ClientForm;
