import {ConfirmationDialog, date, FormDialog, name} from '@management-ui/core';
import {Add, Delete, Edit} from '@mui/icons-material';
import {IconButton, Tooltip} from '@mui/material';
import * as React from 'react';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import PartForm from '../../forms/PartForm';
import useTableStyles from './tableStyles';

const Parts = ({job = null}) => {
  const classes = useTableStyles();
  const services = useContext(ServiceContext);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [parts, setParts] = useState([]);
  const initialised = useRef(false);

  useEffect(() => {
    if (job && !initialised.current) {
      initialised.current = true;
      setLoading(true);
      services.job.getParts(job).then(list => {
        setParts(list);
        setLoading(false);
        setLoaded(true);
      }).catch(() => {
        setLoading(false);
        setLoaded(true);
      });
    }
  }, [services, job]);

  const handleDelete = useCallback((confirmed) => {
    setShowDelete(false);
    if (confirmed) {
      setLoading(true);
      services.job.deletePart(job, selected).then(list => {
        setLoading(false);
        setParts(list);
      }).catch(() => setLoading(false));
    }
  }, [services, job, selected]);

  return (
    <div className={[classes.container, loading ? classes.loading : ''].join(' ')}>
      {job.completed ? null : (
        <div className={classes.actions}>
          <Tooltip title="Add New Part">
            <IconButton onClick={() => setSelected(null) || setShowEdit(true)}>
              <Add/>
            </IconButton>
          </Tooltip>
        </div>
      )}
      <div className={classes.row}>
        <div className={classes.header}>Name</div>
        <div className={classes.header}>Quantity</div>
        <div className={classes.header}>Allocated</div>
        <div className={classes.action}/>
        {job.completed ? null : <div className={classes.action}/>}
      </div>
      {loaded ? (parts.length > 0 ? (
        parts.map((part, index) => (
          <div key={index} className={classes.row}>
            <div className={classes.column}>{part.name}</div>
            <div className={classes.column}>{part.quantity}</div>
            <div className={classes.column}>{part.visits?.length ? (
              <ul>{part.visits.map((visit, index) => (
                <li key={index}>
                  {name(visit.user)}<br/>
                  {date(visit.date)} - {visit.time}
                </li>
              ))}</ul>
            ) : null}</div>
            <div className={classes.action}>
              <Tooltip title="Edit Part">
                <IconButton onClick={() => setSelected(part) || setShowEdit(true)}>
                  <Edit/>
                </IconButton>
              </Tooltip>
            </div>
            {job.completed ? null : (
              <div className={classes.action}>
                <Tooltip title="Delete Part">
                  <IconButton onClick={() => setSelected(part) || setShowDelete(true)}>
                    <Delete/>
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
        ))
      ) : (
        <div className={classes.noRecords}>There are no parts associated with this job</div>
      )) : null}
      <FormDialog
        title={selected ? 'Edit Part' : 'Add New Part'}
        open={showEdit}
        maxWidth="sm"
        onClose={() => setShowEdit(false)}
        render={(props) => (
          <PartForm
            {...props}
            part={selected ?? {}}
            job={job}
            onSaved={(parts) => setShowEdit(false) || setParts(parts)}
          />
        )}
      />
      <ConfirmationDialog
        title="Delete Part"
        message="Are you sure you want to delete this part?"
        open={showDelete}
        onClose={handleDelete}
      />
    </div>
  );
};

export default Parts;
