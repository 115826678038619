import {
  Checkbox,
  DateField,
  name,
  Panes,
  prefixWithSeparator,
  RelationAutocomplete,
  Select,
  TextField
} from '@management-ui/core';
import {Box} from '@mui/material';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {ServiceContext} from '../../../../components/Services';
import JobDetailsForm from '../JobDetailsForm';

const TIMES = ['AM', '10-2', 'PM', 'Other'].map(time => ({title: time, value: time}));
const UNITS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(unit => ({title: `${unit}`, value: unit}));

const BookingFields = ({visit = {}, job = null, prefix = ''}) => {
  const services = useContext(ServiceContext);
  const [users, setUsers] = useState([]);
  const {/** @var {function(string): string} */watch} = useFormContext();
  const time = watch(useMemo(() => `${prefixWithSeparator(prefix)}time`, [prefix]));

  useEffect(() => {
    services.user.getUsers({filter: {is_technician: '1', is_active: '1'}}, 1, 100)
      .then((list) => setUsers(list.data.map((user) => ({title: name(user), value: user.id}))))
      .catch(() => setUsers([]));
  }, [services]);

  const handleReset = useCallback((subject) => {
    if (subject.job) {
      subject.job_id = subject.job.id;
      subject.job_reference = subject.job.reference;
    }
    if (subject.user) {
      subject.user_id = subject.user.id;
    }
    if (!subject.id && job) {
      subject.address = job.address;
      subject.postcode = job.postcode;
    }
    return subject;
  }, [job]);

  return (
    <Panes
      entity={visit}
      prefix={prefix}
      onReset={handleReset}
      panes={[
        {
          title: 'Visit Details',
          fields: [
            ...(job ? [] : [
              <RelationAutocomplete
                valueName="job_id"
                titleName="job_reference"
                prefix={prefix}
                label="Job"
                rules={{required: 'Please select a job'}}
                loadOptions={query => (
                  services.job.getJobs({filter: {search: query}}, 1, 50)
                    .then(response => response.data.map(job => ({title: job.reference, value: job.id})))
                    .catch(() => [])
                )}
                addForm={{
                  title: 'Add New Job',
                  render: (props) => <JobDetailsForm job={{}} {...props}/>,
                  onSaved: (job) => ({title: job.reference, value: job.id}),
                }}
              />
            ]),
            <Select
              name="user_id"
              prefix={prefix}
              label="Technician"
              options={users}
              rules={{required: 'Please select a technician'}}
            />,
            <DateField
              name="date"
              prefix={prefix}
              label="Date"
              rules={{required: 'Please select a date'}}
            />,
            <Select
              name="time"
              prefix={prefix}
              label="Time"
              options={TIMES}
              rules={{required: 'Please select a time'}}
            />,
            <Select
              name="units"
              prefix={prefix}
              label="Units"
              options={UNITS}
              rules={{required: 'Please select a length in units'}}
            />,
            <Box display={time === 'Other' ? 'block' : 'none'}>
              <TextField
                name="special_requirements"
                prefix={prefix}
                label="Special Time Requirements"
              />
            </Box>,
            <Checkbox
              name="confirmed"
              prefix={prefix}
              label="Is this booking confirmed?"
            />,
            <TextField
              name="notes"
              prefix={prefix}
              label="Notes"
            />
          ]
        }, {
          title: 'Job Location',
          fields: [
            <TextField
              name="address"
              prefix={prefix}
              label="Address"
              fieldProps={{
                multiline: true,
                rows: 5
              }}
            />,
            <TextField
              name="postcode"
              prefix={prefix}
              label="Postcode"
            />,
          ]
        }
      ]}
      title={''}
    />
  );
}

export default BookingFields;
