import {Panes, TextField} from '@management-ui/core';
import React from 'react';

const Fields = ({notification = {}, prefix = ''}) => (
  <Panes
    entity={notification}
    prefix={prefix}
    panes={[
      {
        title: 'Content',
        fields: [
          <TextField
            name="title"
            prefix={prefix}
            label="Title"
            rules={{required: 'Please enter a title'}}
          />,
          <TextField
            name="body"
            prefix={prefix}
            label="Body"
            rules={{required: 'Please enter a message body'}}
            fieldProps={{multiline: true, rows: 10}}
          />,
        ]
      }
    ]}
    title={''}/>
);

export default Fields;
