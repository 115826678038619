import {Alert, Button, LinearProgress, Link, Snackbar, TextField} from '@mui/material';
import {makeStyles} from '@mui/styles';
import validator from 'email-validator';
import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  form: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  progress: {
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0
  }
}));

const ResetPassword = ({routes, onResetPassword}) => {
  const classes = useStyles();
  const [errorOpen, setErrorOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const handleSubmit = useCallback(e => {
    e.preventDefault();
    let valid = true;
    setEmailError('');
    setPasswordError('');
    if (!code) {
      setCodeError('Please enter the code you received in the email');
      valid = false;
    }
    if (!email) {
      setEmailError('Please enter an email address');
      valid = false;
    } else if (!validator.validate(email)) {
      setEmailError('Please enter a valid email address');
      valid = false;
    }
    if (!password) {
      setPasswordError('Please enter your password');
      valid = false;
    } else if (password !== confirmPassword) {
      setPasswordError('Please ensure your passwords match');
      valid = false;
    }
    if (valid) {
      setLoading(true);
      onResetPassword(email, password, code)
        .catch(() => {
          setLoading(false);
          setErrorOpen(true);
        });
    }
  }, [onResetPassword, code, email, password, confirmPassword]);

  let loadingBar = null;
  if (loading) {
    loadingBar = <LinearProgress className={classes.progress}/>;
  }

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit}>
      <TextField
        name="code"
        id="code"
        label="Code"
        error={!!codeError}
        helperText={codeError ?? ''}
        value={code}
        onChange={event => setCode(event.target.value)}
        variant="outlined"
        margin="normal"
        required
        fullWidth
      />
      <TextField
        name="email"
        id="email"
        label="Email Address"
        error={!!emailError}
        helperText={emailError ?? ''}
        value={email}
        onChange={event => setEmail(event.target.value)}
        autoComplete="email"
        variant="outlined"
        margin="normal"
        required
        fullWidth
      />
      <TextField
        name="password"
        id="password"
        label="Password"
        error={!!passwordError}
        helperText={passwordError ?? ''}
        value={password}
        onChange={event => setPassword(event.target.value)}
        type="password"
        autoComplete="current-password"
        variant="outlined"
        margin="normal"
        required
        fullWidth
      />
      <TextField
        name="confirmPassword"
        id="confirmPassword"
        label="Confirm Password"
        value={confirmPassword}
        onChange={event => setConfirmPassword(event.target.value)}
        type="password"
        autoComplete="current-password"
        variant="outlined"
        margin="normal"
        required
        fullWidth
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={handleSubmit}
      >
        Reset Password
      </Button>
      <Link component={RouterLink} to={`${routes.login}`} variant="body2">
        Cancel and Log-in
      </Link>
      <Snackbar open={errorOpen} autoHideDuration={4000} onClose={() => setErrorOpen(false)}>
        <Alert onClose={() => setErrorOpen(false)} severity="error">
          Sorry your the details you entered are incorrect, please try again
        </Alert>
      </Snackbar>
      {loadingBar}
    </form>
  );
}

ResetPassword.propTypes = {
  routes: PropTypes.object,
  onResetPassword: PropTypes.func
};

export default ResetPassword;
