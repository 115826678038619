import {Panes, TextField} from '@management-ui/core';
import React from 'react';

const Fields = ({job = {}, prefix = ''}) => (
  <Panes
    entity={job}
    prefix={prefix}
    panes={[
      {
        title: 'Product Details',
        fields: [
          <TextField
            name="manufacturer"
            prefix={prefix}
            label="Manufacturer"
            fieldProps={{disabled: job.completed}}
          />,
          <TextField
            name="model"
            prefix={prefix}
            label="Model"
            fieldProps={{disabled: job.completed}}
          />,
          <TextField
            name="material"
            prefix={prefix}
            label="Material"
            fieldProps={{disabled: job.completed}}
          />,
          <TextField
            name="retailer"
            prefix={prefix}
            label="Retailer"
            fieldProps={{disabled: job.completed}}
          />,
          <TextField
            name="colour"
            prefix={prefix}
            label="Colour"
            fieldProps={{disabled: job.completed}}
          />,
          <TextField
            name="configuration"
            prefix={prefix}
            label="Configuration"
            fieldProps={{disabled: job.completed}}
          />,
        ]
      }, {
        title: 'Job Information',
        fields: [
          <TextField
            name="age"
            prefix={prefix}
            label="Age"
            fieldProps={{disabled: job.completed}}
          />,
          <TextField
            name="issue"
            prefix={prefix}
            label="Issue"
            fieldProps={{
              multiline: true,
              rows: 4,
              disabled: job.completed
            }}
          />,
          <TextField
            name="parts_from_client"
            prefix={prefix}
            label="Parts from Client"
            fieldProps={{
              multiline: true,
              rows: 4,
              disabled: job.completed
            }}
          />,
          <TextField
            name="parts_from_supplier"
            prefix={prefix}
            label="Parts from Supplier"
            fieldProps={{
              multiline: true,
              rows: 4,
              disabled: job.completed
            }}
          />,
        ]
      }
    ]}
    title={''}
  />
);

export default Fields;
