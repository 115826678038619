import {name} from '@management-ui/core';
import {reorder} from '@management-ui/core/utilities';
import {DragIndicator} from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {styled} from '@mui/material';
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {ServiceContext} from '../../../../components/Services';

const List = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: 0,
  padding: 0,

  '& > h3': {
    cursor: 'pointer',
    color: theme.palette.common.white,
    fontSize: '1em',
    margin: 0,
    padding: theme.spacing(0.5, 1)
  },

  '& > p': {
    color: theme.palette.grey['500'],
    margin: 0,
    padding: theme.spacing(2, 1),
  },

  '& .event': {
    transition: '0.25s opacity ease-in-out',
  },

  '&.loading': {
    '& .event': {
      opacity: 0.5,
    },
  },

  '&.am': {
    '& > h3': {
      backgroundColor: theme.palette['times'].am.dark
    },

    '& .event': {
      backgroundColor: theme.palette['times'].am.main
    }
  },

  '&.tenTwo': {
    '& > h3': {
      backgroundColor: theme.palette['times'].tenTwo.dark
    },

    '& .event': {
      backgroundColor: theme.palette['times'].tenTwo.main
    }
  },

  '&.pm': {
    '& > h3': {
      backgroundColor: theme.palette['times'].pm.dark
    },

    '& .event': {
      backgroundColor: theme.palette['times'].pm.main
    }
  },

  '&.other': {
    '& > h3': {
      backgroundColor: theme.palette['times'].other.dark
    },

    '& .event': {
      backgroundColor: theme.palette['times'].other.main
    }
  }
}));

const DropArea = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const Event = styled('div')(({theme}) => ({
  borderTop: `1px solid ${theme.palette.grey['300']}`,
  cursor: 'pointer',
  display: 'flex',
  margin: 0,

  '&:first-of-type': {
    borderTop: 0,
  },

  '& .handle': {
    alignItems: 'center',
    display: 'flex',
    flex: '0 0 24px',
    justifyContent: 'center',
    padding: 4,
    width: 24,

    '& svg': {
      height: 'auto',
      width: '100%',
    }
  },

  '& .details': {
    flex: 1,
    lineHeight: theme.spacing(3),
    padding: theme.spacing(1),
  }
}));

const SlotHolder = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  lineHeight: '1.4em',
  position: 'relative',

  '&.complete': {
    '& i, & span': {
      opacity: 0.25,
    },
  },

  '& .technician': {
    fontSize: '0.8em',
    lineHeight: '1.2em',
    padding: theme.spacing(1, 0, 0),
  },

  '& .units': {
    fontSize: '0.8em',
    fontWeight: '600',
    lineHeight: '1.2em',
  },

  '& svg': {
    height: 20,
    left: -26,
    position: 'absolute',
    top: 4,
    width: 20,
  },

  '&.am': {
    '& svg': {
      color: theme.palette['times'].am.dark
    },
  },

  '&.tenTwo': {
    '& svg': {
      color: theme.palette['times'].tenTwo.dark
    },
  },

  '&.pm': {
    '& svg': {
      color: theme.palette['times'].pm.dark
    },
  },

  '&.other': {
    '& svg': {
      color: theme.palette['times'].other.dark
    },
  }
}));

const VisitSlot = ({visit, slot}) => (
  <SlotHolder className={[slot === '10-2' ? 'tenTwo' : slot.toLowerCase(), ...(visit['completed_at'] ? ['complete'] : [])].join(' ')}>
    {visit.job ? <i>{visit.job.reference}</i> : null}
    <span><strong>{visit.job.customer}</strong>{visit.job.client ? ` (${visit.job.client.name})` : ''}</span>
    {visit.postcode ? <span>{visit.postcode}</span> : null}
    {visit.user ? <span className="technician">{name(visit.user)}</span> : null}
    {visit.units ? <span className="units">{visit.units} unit{visit.units === 1 ? '' : 's'}</span> : null}
    {visit.job['completed_at'] ? <CheckCircleIcon/> : null}
  </SlotHolder>
);

const Slot = ({events: allEvents, day, slot, slotIndex, onSelectSlot, onSelectVisit, filters, onVisitsUpdated}) => {
  const services = useContext(ServiceContext);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const loaded = useRef('');

  useEffect(() => {
    const key = JSON.stringify(allEvents);
    if (loaded.current !== key) {
      loaded.current = key;
      setEvents(allEvents.filter(({visit}) => visit.time === slot).sort((e1, e2) => e1.visit.index > e2.visit.index ? 1 : -1));
    }
  }, [allEvents, slot]);

  const handleDragEnd = useCallback((result) => {
    const reordered = reorder([...events], result.source.index, result.destination.index);
    setEvents(reordered);
    setLoading(true);
    services.visit.reorderVisits(filters, reordered.map(({visit: {id}}, index) => ({id, index: index + 1})))
      .then((updated) => {
        onVisitsUpdated(updated);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [filters, onVisitsUpdated, services, events]);

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <List
        key={slotIndex}
        className={[slot === '10-2' ? 'tenTwo' : slot.toLowerCase(), ...(loading ? ['loading'] : [])].join(' ')}>
        <h3 onClick={onSelectSlot}>{slot}</h3>
        {events.length ? (
          <Droppable isDropDisabled={loading} droppableId={`${day.format('DDMMYYYY')}-${slot}`}>
            {(provided) => (
              <>
                <DropArea {...provided.droppableProps} ref={provided.innerRef}>
                  {events.map(({visit}, visitIndex) => (
                    <Draggable key={`${visit.id}`} draggableId={`${visit.id}`} index={visitIndex}>
                      {(provided) => (
                        <Event
                          className="event"
                          style={provided.draggableProps.style}
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                          onClick={(e) => e.stopPropagation() || onSelectVisit(visit)}
                          key={`${slotIndex}-${visitIndex}`}
                        >
                          <div className="handle" {...provided.dragHandleProps}><DragIndicator/></div>
                          <div className="details"><VisitSlot visit={visit} slot={slot}/></div>
                        </Event>
                      )}
                    </Draggable>
                  ))}
                </DropArea>
                {provided.placeholder}
              </>
            )}
          </Droppable>
        ) : <p>There are no visits booked for this slot</p>}
      </List>
    </DragDropContext>
  );
};

export default Slot;
