import {Breadcrumbs} from '@management-ui/core';
import {Paper} from '@mui/material';
import * as React from 'react';
import routes from '../../../../routes';
import Table from './Table';

const Visits = () => (
  <div>
    <Breadcrumbs crumbs={[{title: 'Visits', link: routes.visits.index}, {title: 'List'}]}/>
    <Paper>
      <Table/>
    </Paper>
  </div>
);

export default Visits;
