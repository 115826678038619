import {styled} from '@mui/styles';
import React from 'react';

const Container = styled('div')(({theme}) => ({
  display: 'flex',
  padding: `0 0 ${theme.spacing(1)}`,
}));

const Inner = styled('div')(({theme}) => ({
  borderRadius: 30,
  border: `1px solid ${theme.palette.grey['700']}`,
  color: theme.palette.grey['700'],
  fontWeight: 600,
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  whiteSpace: 'nowrap',

  '&.jobOngoing': {
    borderColor: theme.palette.jobStatuses.ongoing.text,
    backgroundColor: theme.palette.jobStatuses.ongoing.background,
    color: theme.palette.jobStatuses.ongoing.text,
  },

  '&.jobComplete': {
    borderColor: theme.palette.jobStatuses.complete.text,
    backgroundColor: theme.palette.jobStatuses.complete.background,
    color: theme.palette.jobStatuses.complete.text,
  },

  '&.visitUnconfirmed': {
    borderColor: theme.palette.visitStatuses.unconfirmed.text,
    backgroundColor: theme.palette.visitStatuses.unconfirmed.background,
    color: theme.palette.visitStatuses.unconfirmed.text,
  },

  '&.visitToDo': {
    borderColor: theme.palette.visitStatuses.toDo.text,
    backgroundColor: theme.palette.visitStatuses.toDo.background,
    color: theme.palette.visitStatuses.toDo.text,
  },

  '&.visitComplete': {
    borderColor: theme.palette.visitStatuses.complete.text,
    backgroundColor: theme.palette.visitStatuses.complete.background,
    color: theme.palette.visitStatuses.complete.text,
  },
}));

const slugStatus = (status) => (
  status.split(' ')
    .map((word) => `${word.substring(0, 1).toUpperCase()}${word.substring(1).toLowerCase()}`)
    .join('')
);

const Status = ({type, status}) => status ? (
  <Container>
    <Inner className={`${type}${slugStatus(status)}`}>{status}</Inner>
  </Container>
) : null;

export default Status;
