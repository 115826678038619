import {Breadcrumbs, DataTable, dateTime} from '@management-ui/core';
import {Paper} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../components/Services';
import routes from '../../../routes';
import NotificationForm from '../forms/NotificationForm';

const Notifications = () => {
  const services = useContext(ServiceContext);
  const history = useHistory();

  const goToDetail = useCallback((notification) => {
    history.push(reverse(routes.notifications.detail, {id: notification.id}));
  }, [history]);

  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Notifications'}]}/>
      <Paper>
        <DataTable
          title="Notifications"
          options={{
            search: false,
            sorting: false,
            draggable: false
          }}
          columns={useMemo(() => [
            {title: 'Title', field: 'title'},
            {title: 'Date/Time Created', field: 'created_at', render: notification => dateTime(notification.created_at)},
            {title: 'Date/Time Sent', field: 'sent_at', render: notification => notification.sent_at ? dateTime(notification.sent_at) : '-'},
          ], [])}
          loadData={query => new Promise((resolve, reject) => {
            services.notification.getTableNotifications(query)
              .then(response => {
                resolve({
                  data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
                });
              }).catch((e) => console.log(e) || reject());
          })}
          newForm={{
            title: 'Add New Notification',
            render: (props) => <NotificationForm notification={{}} {...props}/>,
            onSaved: goToDetail
          }}
          onRowClick={goToDetail}
        />
      </Paper>
    </div>
  );
};

export default Notifications;
