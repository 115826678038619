import {Checkbox, Panes, prefixWithSeparator, TextField} from '@management-ui/core';
import {InputAdornment} from '@mui/material';
import numeral from 'numeral';
import React, {useEffect, useMemo, useState} from 'react';
import {useFormContext} from 'react-hook-form';

const PricingFields = ({visit = {}, prefix = ''}) => {
  const {setValue, /** @var {function(string): string} */watch} = useFormContext();
  const [vatStatus, setVATStatus] = useState('');

  useEffect(() => {
    setVATStatus(visit?.job?.client ? '(exc VAT)' : '(inc VAT)');
  }, [visit])

  const inspection = watch(`${prefixWithSeparator(prefix)}inspection_cost`);
  const repair = watch(`${prefixWithSeparator(prefix)}repair_cost`);
  const work = watch(`${prefixWithSeparator(prefix)}work_cost`);
  const missed = watch(`${prefixWithSeparator(prefix)}is_appointment_missed`);
  const appointment = watch(`${prefixWithSeparator(prefix)}missed_appointment_cost`);
  const total = useMemo(() => `${prefixWithSeparator(prefix)}total_cost`, [prefix]);

  useEffect(() => {
    setValue(
      total,
      numeral([
        inspection,
        repair,
        work,
        missed ? appointment : 0
      ].reduce((p, c) => p + Number(c) ?? 0, 0)).format('0,0.00')
    );
  }, [setValue, inspection, repair, work, missed, appointment, total]);

  const labour = watch(`${prefixWithSeparator(prefix)}estimated_labour_cost`);
  const parts = watch(`${prefixWithSeparator(prefix)}estimated_parts_cost`);
  const estimated = useMemo(() => `${prefixWithSeparator(prefix)}estimated_total_cost`, [prefix]);

  useEffect(() => {
    setValue(
      estimated,
      numeral([labour, parts,].reduce((p, c) => p + Number(c) ?? 0, 0)).format('0,0.00')
    );
  }, [setValue, labour, parts, work, estimated]);

  const fieldProps = useMemo(() => ({
    InputProps: {
      startAdornment: <InputAdornment position="start">&pound;</InputAdornment>
    }
  }), []);

  return (
    <Panes
      entity={visit}
      prefix={prefix}
      panes={[
        {
          title: 'Costs',
          fields: [
            <TextField
              name="inspection_cost"
              prefix={prefix}
              label={`Inspection Cost ${vatStatus}`}
              fieldProps={fieldProps}
            />,
            <TextField
              name="repair_cost"
              prefix={prefix}
              label={`Repair Cost ${vatStatus}`}
              fieldProps={fieldProps}
            />,
            <TextField
              name="work_cost"
              prefix={prefix}
              label={`Parts Cost ${vatStatus}`}
              fieldProps={fieldProps}
            />,
            <Checkbox
              name="is_appointment_missed"
              prefix={prefix}
              label="Appointment Missed?"
            />,
            <TextField
              name="missed_appointment_cost"
              prefix={prefix}
              label={`Missed Appointment Cost ${vatStatus}`}
              fieldProps={{disabled: !missed, ...fieldProps}}
            />,
            <TextField
              name="total_cost"
              prefix={prefix}
              label={`Total Cost ${vatStatus}`}
              fieldProps={{disabled: true, ...fieldProps}}
            />,
          ]
        }, {
          title: 'Estimates',
          fields: [
            <TextField
              name="estimated_labour_cost"
              prefix={prefix}
              label={`Estimated Labour Cost ${vatStatus}`}
              fieldProps={fieldProps}
            />,
            <TextField
              name="estimated_parts_cost"
              prefix={prefix}
              label={`Estimated Parts Cost ${vatStatus}`}
              fieldProps={fieldProps}
            />,
            <TextField
              name="estimated_total_cost"
              prefix={prefix}
              label={`Total Estimated Cost ${vatStatus}`}
              fieldProps={fieldProps}
            />,
          ]
        }
      ]}
      title={''}
    />
  );
}

export default PricingFields;
