import React from 'react';
import ReactDOM from 'react-dom';
import App from './modules/App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import Services from './components/Services';
import {store} from './store';

ReactDOM.render(
  <Services><Provider store={store}><App /></Provider></Services>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
