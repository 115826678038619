import {Paper, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import moment from 'moment';
import * as React from 'react';
import {usePermissions} from '../../../hooks';
import VisitsTable from './Visits/Table';

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2)
  }
}));

export default function Dashboard() {
  const classes = useStyles();
  const {userCan} = usePermissions();

  return (
    <div>
      <Paper className={classes.container}>
        <Typography component="h2" variant="h5">Dashboard</Typography>
      </Paper>
      {userCan(['manage_jobs'], (
        <Paper>
          <VisitsTable title="Today's Visits" range={{
            from: moment().startOf('day'),
            to: moment().startOf('day')
          }}/>
        </Paper>
      ), null)}
    </div>
  );
}
