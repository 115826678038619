import {CircularProgress, IconButton, Paper, Toolbar} from '@mui/material';
import Typography from '@mui/material/Typography';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(() => ({
  title: {
    flexGrow: 1,
  }
}));

/**
 * Display a title bar on `Paper` with controls
 *
 * @module Title
 *
 * @param {string} title Title shown in the bar
 * @param {boolean} loading When true a loading spinner will show in place of any controls
 * @param {ITitleControl[]} controls Used to add icon buttons to the end of the bar
 *
 * @example
 * <Title
 *   title="The Title Bar"
 *   loading={false}
 *   controls={[{icon: <EditIcon/>, onClick: () => console.log('Edit Clicked!')}]}
 * />
 *
 */
const Title = ({title, loading, controls, children}) => {
  const classes = useStyles();
  return (
    <Paper>
      <Toolbar>
        <Typography className={classes.title} component="h2" variant="h5">{title}</Typography>
        {loading ? <CircularProgress/> : (controls ?? []).map((control, index) => (
          <IconButton key={index} color="inherit" onClick={control.onClick}>{control.icon}</IconButton>
        ))}
        {children}
      </Toolbar>
    </Paper>
  );
};

Title.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  controls: PropTypes.array
};

export default Title;
