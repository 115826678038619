import {styled} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';

const Container = styled('div')(() => ({
  display: 'none',

  '&.enabled': {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const Switched = ({tab, currentTab, children}) => {
  const initialised = useRef(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!initialised.current && tab === currentTab) {
      initialised.current = true;
      setLoaded(true);
    }
  }, [tab, currentTab]);

  return loaded ? (
    <Container className={tab === currentTab ? 'enabled' : ''}>{children}</Container>
  ) : null;
};

export default Switched;
