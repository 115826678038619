import {Box, IconButton, List, ListItem, ListItemText, ListSubheader, Paper, Tooltip} from '@mui/material';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.primary.main,

    '&:hover': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
    },
  },
}));

/**
 * Display a pane of details with or without additional content
 *
 * @module DetailPane
 *
 * @param {string} title The title to display at the top of the pane
 * @param {IDetailPaneAction[]} actions An array of actions to be displayed in the header
 * @param {IDetailItem[]} details An array of details to display in a list
 * @param {function[]} dialogs An array of functions which return dialogs to include in the pane
 * @param {number} minHeight A minimum height for the pane
 * @param {boolean} wrapChildren Sets whether the children will appear under the list (true) or alongside (false)
 * @param {boolean} collapseChildrenHeight A boolean to mark whether the height of the children should match the parent
 * @param {any} children Child nodes to display as content
 *
 * @example
 * <DetailPane
 *   title="Details"
 *   actions={[{title: 'Edit', icon: <EditIcon/>, onClick: () => console.log('Edit!')}]}
 *   details={[
 *     {title: 'Name', value: 'Name'},
 *     {title: 'Email Address', value: 'test@example.com'}
 *   ]}
 *   dialogs={[
 *     (props) => <p {...props}>Dialog Component</p>
 *   ]}
 *   minHeight={400}
 *   wrapChildren={true}
 *   collapseChildrenHeight={false}
 * >
 *   <p>Child Content</p>
 * </DetailPane>
 *
 */
const DetailPane = (
  {
    title,
    actions,
    details,
    dialogs,
    minHeight,
    wrapChildren = true,
    collapseChildrenHeight = true,
    children
  }
) => {
  const classes = useStyles();
  return (
    <Paper display="flex" flexDirection="row" overflow="hidden" component={Box} flex="1 0 100%"
           style={minHeight ? {minHeight} : null}>
      <Box display="flex" flexDirection="column" flex={1}>
        <List dense subheader={
          <ListSubheader>
            <Box display="flex" flexDirection="row">
              {title}
              <Box flex={1}/>
              {actions ? actions.map((action, index) => (
                <div key={index}>
                  <Tooltip title={action.title}>
                    <IconButton color="inherit" edge={index === actions.length - 1 ? 'end' : false}
                                onClick={action.onClick}>
                      {action.icon}
                    </IconButton>
                  </Tooltip>
                </div>
              )) : null}
            </Box>
          </ListSubheader>
        }>
          {details ? details.map((detail, index) => (
            <ListItem key={`detail-${index}`}>
              <ListItemText
                primary={detail['link'] ? (
                  <a className={classes.link} href={detail['value']} target="_blank"
                     rel="noreferrer">{detail['value']}</a>
                ) : (detail['route'] ? (
                  <Link to={detail['route']} className={classes.link}>{detail['value']}</Link>
                ) : detail['value'])}
                secondary={detail['title']}
              />
            </ListItem>
          )) : null}
        </List>
        {children && wrapChildren ? (
          <Box display="flex" flexDirection="column" flex={1}>
            {collapseChildrenHeight ? (
              <Box flex={1} position="relative">
                <Box position="absolute" top={0} right={0} bottom={0} left={0} overflow="scroll">
                  {children}
                </Box>
              </Box>
            ) : <Box flex={1} position="relative">{children}</Box>}
          </Box>
        ) : null}
      </Box>
      {children && !wrapChildren ? children : null}
      {dialogs ? dialogs.map((dialog, index) => dialog({key: `dialog-${index}`})) : null}
    </Paper>
  );
}

DetailPane.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.array,
  details: PropTypes.array,
  dialogs: PropTypes.array,
  minHeight: PropTypes.number,
  wrapChildren: PropTypes.bool,
  collapseChildrenHeight: PropTypes.bool
};

export default DetailPane;
