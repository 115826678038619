import {Panes, TextField} from '@management-ui/core';
import React from 'react';

const AdditionalFields = ({visit = {}, prefix = ''}) => (
  <Panes
    entity={visit}
    prefix={prefix}
    panes={[
      {
        title: '',
        fields: [
          <TextField
            name="pricing_list"
            prefix={prefix}
            label="Pricing List"
            fieldProps={{
              multiline: true,
              rows: 5
            }}
          />
        ]
      }, {
        title: '',
        fields: []
      }
    ]}
    title={''}
  />
);

export default AdditionalFields;
