import {Panes, TextField} from '@management-ui/core';
import React from 'react';

const SettingsFields = ({settings = {}, prefix = '', multiplePanes = true, children}) => {
  return (
    <Panes
      entity={settings}
      prefix={prefix}
      panes={[
        {
          title: 'General Settings',
          fields: [
            <TextField
              name="sender"
              prefix={prefix}
              label="Trigger Email Sender"
            />,
          ]
        },
        {
          title: 'Pricing',
          fields: [
            <TextField
              name="vatRate"
              prefix={prefix}
              label="VAT Rate"
            />,
            <TextField
              name="inspectionCost"
              prefix={prefix}
              label="Default Inspection Cost (exc VAT)"
            />,
            <TextField
              name="missedAppointmentCost"
              prefix={prefix}
              label="Missed Appointment Cost (exc VAT)"
            />,
          ]
        }
      ]}
      title={multiplePanes ? null : 'Settings'}
    >{children}</Panes>
  );
};

SettingsFields.getValues = ({sender, vatRate, inspectionCost, missedAppointmentCost}) => ({
  sender, vatRate, inspectionCost, missedAppointmentCost
});

export default SettingsFields;
