import {Breadcrumbs, DataTable, name} from '@management-ui/core';
import {MTableToolbar} from '@material-table/core';
import {Paper} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import YesNo from '../../../../components/YesNo';
import routes from '../../../../routes';
import UserForm from '../../forms/UserForm';
import Filter from './Filter';

const Users = () => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const [state, setState] = useState('active');
  /** @type {({current: DataTable})} */
  const tableRef = useRef();

  const goToDetail = useCallback((user) => {
    history.push(reverse(routes.users.detail, {id: user.id}));
  }, [history]);

  const handleState = useCallback((changed) => {
    setState(changed);
    if (tableRef.current) {
      tableRef.current.refresh();
    }
  }, []);

  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Users'}]}/>
      <Paper>
        <DataTable
          title="Users"
          ref={tableRef}
          tableProps={{
            components: {
              Toolbar: useCallback((props) => (
                <>
                  <MTableToolbar {...props}/>
                  <Filter
                    state={state}
                    onChangeState={handleState}
                  />
                </>
              ), [state, handleState])
            }
          }}
          columns={useMemo(() => [
            {title: 'Name', field: 'name', render: data => name(data)},
            {title: 'Email', field: 'email'},
            {title: 'Technician?', field: 'is_technician', render: data => <YesNo value={data.is_technician}/>},
          ], [])}
          loadData={query => new Promise((resolve, reject) => {
            const filters = {};
            switch (state) {
              case 'active':
                filters['is_active'] = '1';
                break;
              case 'inactive':
                filters['is_active'] = '0';
                break;
              default:
                break;
            }
            services.user.getTableUsers(query, filters)
              .then(response => {
                resolve({
                  data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
                });
              }).catch(() => {
              reject();
            });
          })}
          newForm={{
            title: 'Add New User',
            render: (props) => <UserForm user={{}} {...props}/>
          }}
          onRowClick={goToDetail}
        />
      </Paper>
    </div>
  );
};

export default Users;
