import {AccessHolder} from '@management-ui/core';
import React, {useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import logo from '../../assets/bt-scott.svg';
import {ServiceContext} from '../../components/Services';
import routes from '../../routes';
import {login, resetPassword} from '../../store/actions/auth';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import ResetPassword from './ResetPassword';

export default function Access(props) {
  const services = useContext(ServiceContext);
  const dispatch = useDispatch();

  const handleLogin = useCallback((email, password) => {
    return dispatch(login(email, password));
  }, [dispatch])

  const handleForgotPassword = useCallback((email) => {
    return services.auth.forgotPassword(email, `${window.location.origin}${routes.resetPassword}?token={TOKEN}`)
  }, [services]);

  const handleResetPassword = useCallback((email, password, token) => {
    return dispatch(resetPassword(email, password, token))
  }, [dispatch]);

  return (
    <AccessHolder
      title="B.T. Scott & Son"
      logo={logo}
      logoWidth={300}
      company="B.T. Scott & Son"
      {...props}
    >
      <Switch>
        <Route
          exact
          path={routes.login}
          render={routeProps => <Login {...props} {...routeProps} routes={routes} onLogin={handleLogin}/>}/>
        <Route
          exact
          path={routes.forgotPassword}
          render={routeProps => (
            <ForgotPassword {...props} {...routeProps} routes={routes} onForgotPassword={handleForgotPassword}/>
          )}/>
        <Route
          exact
          path={routes.resetPassword}
          render={routeProps => (
            <ResetPassword {...props} {...routeProps} routes={routes} onResetPassword={handleResetPassword}/>
          )}/>
      </Switch>
    </AccessHolder>
  );
}
