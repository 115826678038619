import {Authenticated} from '@management-ui/core';
import {
  Business,
  Dashboard as DashboardIcon,
  EditNotifications,
  Event,
  Security,
  Storage,
  WorkOutline
} from '@mui/icons-material';
import {Box} from '@mui/material';
import * as React from 'react';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {Redirect, Route, Switch} from 'react-router-dom';
import logo from '../../assets/bt-scott.svg';
import {usePermissions} from '../../hooks';
import routes from '../../routes';
import {logout} from '../../store/actions/auth';
import Client from './screens/Client';
import Clients from './screens/Clients';
import Dashboard from './screens/Dashboard';
import Job from './screens/Job';
import NewVisit from './screens/Job/NewVisit';
import Visit from './screens/Job/Visit';
import Jobs from './screens/Jobs';
import Management from './screens/Management';
import Notification from './screens/Notification';
import Notifications from './screens/Notifications';
import User from './screens/User';
import Users from './screens/Users';
import Visits from './screens/Visits';
import Bookings from './screens/Visits/Bookings';

const Admin = (props) => {
  const {user, userCan} = usePermissions();
  const dispatch = useDispatch();

  const userCanOrRedirect = useCallback((permission, can = null) => {
    const hasPermission = userCan(permission, can);
    return hasPermission ?? <Redirect to={`${routes.dashboard}`}/>;
  }, [userCan]);

  return (
    <Authenticated
      title="B.T. Scott & Son"
      logo={(
        <Box marginY={1} display="flex" alignItems="center" justifyContent="center">
          <img
            src={logo}
            alt="B.T. Scott & Son"
            style={{width: '80%'}}/>
        </Box>
      )}
      userName={user ? user.first_name : ''}
      nav={[
        {title: 'Dashboard', route: routes.dashboard, icon: <DashboardIcon/>},
        ...userCan(
          ['manage_clients'],
          [{
            title: 'Clients',
            route: routes.clients.index,
            icon: <Business/>
          }], []),
        ...userCan(
          ['manage_jobs'],
          [
            {
              title: 'Visits',
              route: routes.visits.index,
              icon: <Event/>
            },
            {
              title: 'Jobs',
              route: routes.jobs.index,
              icon: <WorkOutline/>
            }
          ], []),
        ...userCan(['manage_notifications'], [
          {title: 'Notifications', route: routes.notifications.index, icon: <EditNotifications/>}
        ], []),
        ...userCan(['manage_users'], [
          {title: 'Users', route: routes.users.index, icon: <Security/>}
        ], []),
        ...userCan(['change_settings', 'manage_attributes'], [{
          title: 'Management',
          route: routes.management,
          icon: <Storage/>
        }], []),
      ]}
      onLogout={() => dispatch(logout())}
    >
      <Switch>

        <Route
          exact
          path={routes.dashboard}
          render={routeProps => <Dashboard {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.clients.index}
          render={routeProps => userCanOrRedirect(['manage_clients'], <Clients {...props} {...routeProps} />)}/>
        <Route
          exact
          path={routes.clients.detail}
          render={routeProps => userCanOrRedirect(['manage_clients'], <Client {...props} {...routeProps} />)}/>

        <Route
          exact
          path={routes.visits.index}
          render={routeProps => userCanOrRedirect(['manage_jobs'], <Bookings {...props} {...routeProps} />)}/>
        <Route
          exact
          path={routes.visits.list}
          render={routeProps => userCanOrRedirect(['manage_jobs'], <Visits {...props} {...routeProps} />)}/>

        <Route
          exact
          path={routes.jobs.index}
          render={routeProps => userCanOrRedirect(['manage_jobs'], <Jobs {...props} {...routeProps} />)}/>
        <Route
          exact
          path={routes.jobs.detail}
          render={routeProps => userCanOrRedirect(['manage_jobs'], <Job {...props} {...routeProps} />)}/>
        <Route
          exact
          path={routes.jobs.newVisit}
          render={routeProps => userCanOrRedirect(['manage_jobs'], <NewVisit {...props} {...routeProps} />)}/>
        <Route
          exact
          path={routes.jobs.visit.detail}
          render={routeProps => userCanOrRedirect(['manage_jobs'], <Visit {...props} {...routeProps} />)}/>
        <Route
          exact
          path={routes.jobs.visit.tab}
          render={routeProps => userCanOrRedirect(['manage_jobs'], <Visit {...props} {...routeProps} />)}/>
        <Route
          exact
          path={routes.jobs.tab}
          render={routeProps => userCanOrRedirect(['manage_jobs'], <Job {...props} {...routeProps} />)}/>

        <Route
          exact
          path={routes.notifications.index}
          render={routeProps => userCanOrRedirect(['manage_notifications'],
            <Notifications {...props} {...routeProps} />)}/>
        <Route
          exact
          path={routes.notifications.detail}
          render={routeProps => userCanOrRedirect(['manage_notifications'],
            <Notification {...props} {...routeProps} />)}/>

        <Route
          exact
          path={routes.users.index}
          render={routeProps => userCanOrRedirect(['manage_users'], <Users {...props} {...routeProps} />)}/>
        <Route
          exact
          path={routes.users.detail}
          render={routeProps => userCanOrRedirect(['manage_users'], <User {...props} {...routeProps} />)}/>

        <Route
          exact
          path={routes.management}
          render={routeProps => userCanOrRedirect(
            ['change_settings', 'manage_attributes'],
            <Management {...props} {...routeProps} />
          )}/>

      </Switch>
    </Authenticated>
  );
};

export default Admin;
