import {Panes, Select} from '@management-ui/core';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';

const Fields = ({visit, existing, part = {}, prefix = ''}) => {
  const services = useContext(ServiceContext);
  const [parts, setParts] = useState();
  const loaded = useRef(false);

  useEffect(() => {
    if (!loaded.current) {
      loaded.current = true;
      services.job.getParts(visit.job).then(response => {
        setParts(response
          .filter(({id}) => existing.findIndex(e => e.id === id) < 0)
          .map(({id, name, quantity}) => ({title: `${name} (x${quantity})`, value: id}))
        );
      }).catch(() => setParts([]));
    }
  }, [services, visit, existing]);

  return (
    <Panes
      entity={part}
      prefix={prefix}
      panes={[
        {
          title: 'Part to Link',
          fields: [
            <Select
              name="part_id"
              prefix={prefix}
              label="Part"
              options={parts}
              rules={{required: 'Please a part to link to this visit'}}
            />
          ]
        }
      ]}
      title=""/>
  );
};

export default Fields;
