import {Panes, RelationAutocomplete, Select, TextField} from '@management-ui/core';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import ClientForm from '../ClientForm';

const Fields = ({job = {}, prefix = ''}) => {
  const services = useContext(ServiceContext);
  const [types, setTypes] = useState([]);

  useEffect(() => {
    services.attribute.jobType.getAttributes()
      .then((list) => setTypes(list.map(({id, name}) => ({title: name, value: id}))))
      .catch(() => setTypes([]));
  }, [services]);

  const handleReset = useCallback((subject) => {
    if (subject.client) {
      subject.client_id = subject.client.id;
      subject.client_name = subject.client.name;
    }
    if (subject.type) {
      subject.type_id = subject.type.id;
    }
    return subject;
  }, []);

  return (
    <Panes
      entity={job}
      prefix={prefix}
      onReset={handleReset}
      panes={[
        {
          title: 'Core Details',
          fields: [
            <TextField
              name="reference"
              prefix={prefix}
              label="Reference"
              rules={{required: 'Please enter a job reference'}}
            />,
            <RelationAutocomplete
              valueName="client_id"
              titleName="client_name"
              prefix={prefix}
              label="Client"
              rules={{required: 'Please select a client'}}
              loadOptions={query => (
                services.client.getClients({filter: {search: query}}, 1, 50)
                  .then(response => response.data.map(client => ({title: client.name, value: client.id})))
                  .catch(() => [])
              )}
              addForm={{
                title: 'Add New Client',
                onSaved: (client) => ({title: client.name, value: client.id}),
                render: (props) => (
                  <ClientForm
                    {...props}
                    type="jobClient"
                    client={{}}
                  />
                )
              }}
            />,
            <Select
              name="type_id"
              prefix={prefix}
              label="Job Type"
              options={types}
              rules={{required: 'Please select a type'}}
            />
          ]
        }, {
          title: 'Customer Details',
          fields: [
            <TextField
              name="customer"
              prefix={prefix}
              label="Customer Name"
            />,
            <TextField
              name="address"
              prefix={prefix}
              label="Address"
              fieldProps={{
                multiline: true,
                rows: 5
              }}
            />,
            <TextField
              name="postcode"
              prefix={prefix}
              label="Postcode"
            />,
            <TextField
              name="phone"
              prefix={prefix}
              label="Customer Phone Number"
            />,
            <TextField
              name="email"
              prefix={prefix}
              label="Customer Email Address"
            />,
          ]
        }
      ]}
      title={''}
    />
  );
}

export default Fields;
