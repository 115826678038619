import {ConfirmationDialog, FormDialog} from '@management-ui/core';
import {Add, Delete, Download} from '@mui/icons-material';
import {IconButton, Tooltip} from '@mui/material';
import * as React from 'react';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {ServiceContext} from '../../../../../components/Services';
import VisitImageForm from '../../../forms/VisitImageForm';
import useTableStyles from '../tableStyles';

const Images = ({visit = null}) => {
  const classes = useTableStyles();
  const services = useContext(ServiceContext);
  const [showNew, setShowNew] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const initialised = useRef(false);

  useEffect(() => {
    if (visit && !initialised.current) {
      initialised.current = true;
      setLoading(true);
      services.visit.getImages(visit).then(list => {
        setImages(list);
        setLoading(false);
        setLoaded(true);
      }).catch(() => {
        setLoading(false);
        setLoaded(true);
      });
    }
  }, [services, visit]);

  const handleDownload = useCallback((image) => {
    services.visit.downloadImage(visit.id, image.id)
      .then(() => null)
      .catch(() => null);
  }, [services, visit]);

  const handleDelete = useCallback((confirmed) => {
    setShowDelete(false);
    if (confirmed) {
      setLoading(true);
      services.visit.deleteImage(visit, selected).then(list => {
        setLoading(false);
        setImages(list);
      }).catch(() => setLoading(false));
    }
  }, [services, visit, selected]);

  return (
    <div className={[classes.container, loading ? classes.loading : ''].join(' ')}>
      <div className={classes.actions}>
        <Tooltip title="Add New Image">
          <IconButton onClick={() => setShowNew(true)}>
            <Add/>
          </IconButton>
        </Tooltip>
      </div>
      <div className={classes.row}>
        <div className={classes.header}>Title</div>
        <div className={classes.header}>Filename</div>
        <div className={classes.action}/>
        <div className={classes.action}/>
      </div>
      {loaded ? (images.length > 0 ? (
        images.map((image, index) => (
          <div key={index} className={classes.row}>
            <div className={classes.column}>{image.title ?? '-'}</div>
            <div className={classes.column}>{image.name}</div>
            <div className={classes.action}>
              <Tooltip title="Download Image">
                <IconButton onClick={() => handleDownload(image)}>
                  <Download/>
                </IconButton>
              </Tooltip>
            </div>
            <div className={classes.action}>
              <Tooltip title="Delete Image">
                <IconButton onClick={() => setSelected(image) || setShowDelete(true)}>
                  <Delete/>
                </IconButton>
              </Tooltip>
            </div>
          </div>
        ))
      ) : (
        <div className={classes.noRecords}>There are no images associated with this visit</div>
      )) : null}
      <FormDialog
        title="Add New Image"
        open={showNew}
        maxWidth="sm"
        onClose={() => setShowNew(false)}
        render={(props) => (
          <VisitImageForm
            {...props}
            file={{}}
            visit={visit}
            onSaved={(images) => setShowNew(false) || setImages(images)}
          />
        )}
      />
      <ConfirmationDialog
        title="Delete Image"
        message="Are you sure you want to delete this image?"
        open={showDelete}
        onClose={handleDelete}
      />
    </div>
  );
};

export default Images;
