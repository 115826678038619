import {styled} from '@mui/material';
import React from 'react';
import {useJobStatuses} from '../../../../hooks';

const Container = styled('div')(({theme}) => ({
  borderBottom: `2px solid ${theme.palette.grey['100']}`,
  borderTop: `2px solid ${theme.palette.grey['100']}`,
  display: 'flex',
  flex: 1,
  width: '100%',

  '& > span': {
    alignItems: 'center',
    display: 'flex',
    flex: 0,
    padding: `0 ${theme.spacing(2)}`,
  }
}));

const Statuses = styled('ul')(({theme}) => ({
  display: 'flex',
  flex: 1,
  listStyle: 'none',
  margin: 0,
  padding: `0 ${theme.spacing(2)} 0 0`,
}));

const Status = styled('li')(({theme}) => ({
  background: 'var(--status-background-colour)',
  borderLeft: `2px solid ${theme.palette.grey['100']}`,
  color: 'var(--status-text-colour)',
  cursor: 'pointer',
  fontWeight: '700',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,

  '&:last-of-type': {
    borderRight: `2px solid ${theme.palette.grey['100']}`,
  },

  '&.selected': {
    background: 'var(--status-text-colour)',
    color: 'var(--status-background-colour)',
  }
}));

const Filter = ({status, onChangeStatus}) => {
  const statuses = useJobStatuses();

  return (
    <Container>
      <span>Displaying:</span>
      <Statuses>
        {statuses.map(({title, key, textColour, backgroundColour}, index) => (
          <Status
            key={index}
            className={status === key ? 'selected' : ''}
            style={{'--status-text-colour': textColour, '--status-background-colour': backgroundColour}}
            onClick={() => status === key ? null : onChangeStatus(key)}
          >
            {title}
          </Status>
        ))}
      </Statuses>
    </Container>
  );
}

export default Filter;
