import {createTheme, StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import React, {useEffect} from 'react';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import routes from '../routes';
import {API} from '../services/API';
import {getUser} from '../store/actions/auth';
import Access from './access/Access';
import Admin from './admin/Admin';
import './App.scss';

const theme = createTheme({
  palette: {
    primary: {
      main: '#752828',
      contrastText: '#fff'
    },
    secondary: {
      main: '#f8c34e',
      contrastText: '#752828'
    },
    background: {
      default: '#fafafa',
    },
    times: {
      am: {
        main: '#d4ecff',
        dark: '#388dd0'
      },
      tenTwo: {
        main: '#e7ffd4',
        dark: '#5a9a27'
      },
      pm: {
        main: '#ffeccb',
        dark: '#eab14c'
      },
      other: {
        main: '#e7d4ff',
        dark: '#9a50fa'
      },
    },
    jobStatuses: {
      all: {
        background: '#fff',
        text: '#111111'
      },
      ongoing: {
        background: '#ffefd8',
        text: '#ffac4e'
      },
      complete: {
        background: '#e6f1f6',
        text: '#1eb0ca'
      }
    },
    visitStatuses: {
      all: {
        background: '#fff',
        text: '#111111'
      },
      unconfirmed: {
        background: '#ffe5d8',
        text: '#ff4e4e'
      },
      toDo: {
        background: '#ffefd8',
        text: '#ffac4e'
      },
      complete: {
        background: '#e6f6e9',
        text: '#1eca6e'
      }
    }
  },
  typography: {
    fontFamily: [
      'Roboto'
    ],
    body1: {
      fontSize: '0.875rem'
    }
  }
});

function Axios({children}) {
  const store = useStore();

  useEffect(() => {
    API.initialise(store);
  }, [store]);
  return <>{children}</>;
}

export default function App() {
  const user = useSelector(state => state['auth'].user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user === false) {
      dispatch(getUser());
    }
  }, [user, dispatch]);

  let router = null;

  if (user !== false) {
    if (user) {
      router = (
        <Router>
          <Switch>
            <Route path="/auth"><Redirect to={`${routes.dashboard}`}/></Route>
            <Route path="/"><Admin/></Route>
          </Switch>
        </Router>
      );
    } else {
      router = (
        <Router>
          <Switch>
            <Route path="/auth"><Access/></Route>
            <Route path="/"><Redirect to={`${routes.login}`}/></Route>
          </Switch>
        </Router>
      )
    }
  }
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Axios>
          {router}
        </Axios>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
