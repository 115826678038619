import AddIcon from '@mui/icons-material/Add';
import {Box, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {reorder} from '../../utilities';
import Spinner from '../Spinner';
import Item from './Item';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },

  overlay: {
    alignItems: 'flex-start',
    background: theme.palette.background.paper,
    bottom: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    left: 0,
    opacity: 0,
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    pointerEvents: 'none',
    position: 'absolute',
    right: 0,
    top: 0,
    transition: '0.25s opacity ease-in-out'
  },

  loading: {
    opacity: 0.75,
    pointerEvents: 'all'
  },

  title: {
    borderBottom: `1px solid ${theme.palette.grey['300']}`
  },

  items: {
    display: 'flex',
    flexDirection: 'column',
  },

  item: {
    borderBottom: `1px solid ${theme.palette.grey['300']}`,
    display: 'flex',
    flexDirection: 'column',
  },

  new: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    transition: '0.25s background-color ease-in-out',

    '& span': {
      paddingLeft: theme.spacing(1),
      textTransform: 'uppercase'
    },

    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  }
}));

/**
 * A component for reordering a list of objects
 *
 * @module ReorderingPanel
 *
 * @param {string} title The title for the panel
 * @param {Array} items An array of items to display
 * @param {boolean} loading A flag to show the loading overlay
 * @param {function} onLoading A callback fired when the panel needs to change the loading state
 * @param {function} onNew A function which fires when a new item is requested
 * @param {Array} controls An array of controls to display at the end of a row
 * @param {function} onReorder A callback to be called when the items are being re-ordered
 * @param {function} onReordered A callback to be called the items have been re-ordered
 *
 * @example
 * <ReorderingPanel
 *   title="Items"
 *   loading={loading}
 *   onLoading={(loading) => setLoading(loading)}
 *   items={[
 *     {entity: {id: 1}, attributes: ['Item 1', 'One']},
 *     {entity: {id: 2}, attributes: ['Item 2', 'One']}
 *   ]}
 *   controls={[
 *     {title: 'Edit', icon: <EditIcon/>, onClick: (item) => console.log({item})}
 *   ]}
 * />
 *
 */
const ReorderingPanel = (
  {
    title,
    items,
    loading = false,
    onLoading = () => null,
    onNew,
    controls,
    onReorder = () => new Promise(resolve => resolve()),
    onReordered = () => null
  }
) => {
  const classes = useStyles();

  const handleDragEnd = useCallback((result) => {
    const reordered = reorder([...items], result.source.index, result.destination.index);
    onLoading(true);
    onReorder(reordered.map(item => item.entity)).then((updated) => {
      onLoading(false);
      onReordered(updated);
    }).catch(() => {
      onLoading(false);
    });
  }, [items, onLoading, onReorder, onReordered]);

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className={classes.container}>
        <Box className={classes.title} padding={2}><Typography variant="h6">{title}</Typography></Box>
        <Droppable droppableId="droppable">
          {(provided) => (
            <>
              <div className={classes.items} {...provided.droppableProps} ref={provided.innerRef}>
                {items.length ? items.map((item, index) => (
                  <Draggable key={item.entity.id} draggableId={`${item.entity.id}`} index={index}>
                    {(provided) => (
                      <div
                        className={classes.item}
                        style={provided.draggableProps.style}
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                      >
                        <Item
                          entity={item.entity}
                          attributes={item.attributes}
                          controls={controls}
                          handleProps={provided.dragHandleProps}
                        />
                      </div>
                    )}
                  </Draggable>
                )) : null}
              </div>
              {provided.placeholder}
            </>
          )}
        </Droppable>
        {onNew ? (
          <div className={classes.new} onClick={() => onNew()}>
            <AddIcon/><span>Add New</span>
          </div>
        ) : null}
        <div className={[classes.overlay, loading ? classes.loading : ''].join(' ')}><Spinner/></div>
      </div>
    </DragDropContext>
  );
};

ReorderingPanel.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onLoading: PropTypes.func,
  onNew: PropTypes.func,
  controls: PropTypes.array,
  onReorder: PropTypes.func,
  onReordered: PropTypes.func
}

export default ReorderingPanel;
