import {useTheme} from '@mui/styles';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

export const usePermissions = (permissions = null) => {
  const user = useSelector(state => state['auth'].user);
  const [hasPermission, setHasPermission] = useState(false);

  const userCan = useCallback((permissions, can = null, cant = null) => {
    for (let permission of permissions) {
      if (user.permissions[`can_${permission}`]) {
        return can;
      }
    }
    return cant;
  }, [user]);

  useEffect(() => {
    if (permissions) {
      setHasPermission(userCan(permissions, true, false));
    } else {
      setHasPermission(false);
    }
  }, [permissions, user, userCan])

  return {hasPermission, user, userCan}
}

export const useJobStatuses = () => {
  const theme = useTheme();
  return useMemo(() => [
    {
      title: 'All',
      key: '',
      textColour: theme['palette']['jobStatuses'].all.text,
      backgroundColour: theme['palette']['jobStatuses'].all.background
    },
    {
      title: 'Ongoing',
      key: 'ongoing',
      textColour: theme['palette']['jobStatuses'].ongoing.text,
      backgroundColour: theme['palette']['jobStatuses'].ongoing.background
    },
    {
      title: 'Complete',
      key: 'complete',
      textColour: theme['palette']['jobStatuses'].complete.text,
      backgroundColour: theme['palette']['jobStatuses'].complete.background
    },
  ], [theme]);
}

export const useUserStates = () => {
  const theme = useTheme();
  return useMemo(() => [
    {
      title: 'Active',
      key: 'active',
      textColour: theme['palette'].success.main,
      backgroundColour: theme['palette'].success.contrastText
    },
    {
      title: 'Inactive',
      key: 'inactive',
      textColour: theme['palette'].error.main,
      backgroundColour: theme['palette'].error.contrastText
    },
    {
      title: 'All',
      key: 'all',
      textColour: theme['palette'].common.black,
      backgroundColour: theme['palette'].common.white
    }
  ], [theme]);
}
