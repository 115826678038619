import {useFloatingButtons} from '@management-ui/core';
import FloatingButtons from '@management-ui/core/components/FloatingButtons';
import SaveIcon from '@mui/icons-material/Save';
import {Box, Paper, Typography} from '@mui/material';
import {useTheme} from '@mui/styles';
import React, {useCallback, useContext, useMemo, useRef, useState} from 'react';
import Navigation from '../../../../../components/Navigation';
import {ServiceContext} from '../../../../../components/Services';
import VisitForm from '../../../forms/VisitForm';
import DetailTab from '../DetailTab';
import Images from './Images';

const TABS = [
  {
    id: 'core',
    menuTitle: 'Appointment Details',
  },
  {
    id: 'issues',
    menuTitle: 'Issues Found',
  },
  {
    id: 'images',
    menuTitle: 'Images',
  },
  {
    id: 'additional',
    menuTitle: 'Additional Information',
  },
  {
    id: 'pricing',
    menuTitle: 'Pricing',
  }
];

const Details = ({visit, onVisitUpdated}) => {
  const services = useContext(ServiceContext);
  const theme = useTheme();
  const [selected, setSelected] = useState('core')
  const {buttons, setButtonState} = useFloatingButtons(useMemo(() => ({
    save: {
      colour: 'secondary',
      states: {
        default: {
          label: 'Save',
          icon: SaveIcon,
          spinning: false
        },
        saving: {
          label: 'Saving...',
          spinning: true
        },
        loading: {
          label: 'Loading...',
          spinning: true
        }
      }
    }
  }), []));
  /** @type {({current: VisitForm})} */
  const appointmentRef = useRef();
  /** @type {({current: VisitForm})} */
  const issuesRef = useRef();
  /** @type {({current: VisitForm})} */
  const additionalRef = useRef();
  /** @type {({current: VisitForm})} */
  const pricingRef = useRef();

  const handleSave = useCallback(() => {
    const update = {
      id: visit.id,
      ...(appointmentRef.current.getData()),
      ...(issuesRef.current.getData()),
      ...(additionalRef.current.getData()),
      ...(pricingRef.current.getData())
    };
    setButtonState('save', 'saving');
    services.visit.saveVisit(update).then((updated) => {
      onVisitUpdated(updated);
      setButtonState('save', 'default');
    }).catch(() => {
      setButtonState('save', 'default');
    });
  }, [services, setButtonState, visit, onVisitUpdated]);

  return selected ? (
    <Paper component={Box} marginTop={1} width="100%" overflow="hidden">
      <Box padding={2}>
        <Typography variant="subtitle2" color={theme['palette'].grey['600']}>Visit Report</Typography>
      </Box>
      <Navigation
        items={TABS}
        current={selected}
        onSelect={({id}) => setSelected(id)}
      >
        <DetailTab title="Appointment Details" visible={selected === 'core'}>
          <VisitForm
            ref={appointmentRef}
            job={visit.job}
            visit={visit}
            fieldSet="appointment"
          />
        </DetailTab>
        <DetailTab title="Issues Found" visible={selected === 'issues'}>
          <VisitForm
            ref={issuesRef}
            job={visit.job}
            visit={visit}
            fieldSet="issues"
          />
        </DetailTab>
        <DetailTab title="Images" visible={selected === 'images'}>
          <Images visit={visit}/>
        </DetailTab>
        <DetailTab title="Additional Information" visible={selected === 'additional'}>
          <VisitForm
            ref={additionalRef}
            job={visit.job}
            visit={visit}
            fieldSet="additional"
          />
        </DetailTab>
        <DetailTab title="Pricing" visible={selected === 'pricing'}>
          <VisitForm
            ref={pricingRef}
            job={visit.job}
            visit={visit}
            fieldSet="pricing"
          />
        </DetailTab>
      </Navigation>
      <FloatingButtons buttons={buttons} onClick={handleSave}/>
    </Paper>
  ) : null;
};

export default Details;
