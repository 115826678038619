import {Alert, Button, LinearProgress, Link, Snackbar, TextField} from '@mui/material';
import {makeStyles} from '@mui/styles';
import validator from 'email-validator';
import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  form: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  progress: {
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0
  }
}));

const Login = ({routes, onLogin}) => {
  const classes = useStyles();
  const [errorOpen, setErrorOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const handleSubmit = useCallback(() => {
    let valid = true;
    setEmailError('');
    setPasswordError('');
    if (!email) {
      setEmailError('Please enter an email address');
      valid = false;
    } else if (!validator.validate(email)) {
      setEmailError('Please enter a valid email address');
      valid = false;
    }
    if (!password) {
      setPasswordError('Please enter your password');
      valid = false;
    }
    if (valid) {
      setLoading(true);
      onLogin(email, password).then(() => setLoading(false)).catch(() => {
        setLoading(false);
        setErrorOpen(true);
      });
    }
  }, [onLogin, email, password]);

  return (
    <form className={classes.form} noValidate onSubmit={e => e.preventDefault() || handleSubmit()}>
      <TextField
        name="email"
        id="email"
        label="Email Address"
        error={!!emailError}
        helperText={emailError ?? ''}
        value={email}
        onChange={event => setEmail(event.target.value)}
        autoComplete="email"
        variant="outlined"
        margin="normal"
        required
        fullWidth
      />
      <TextField
        name="password"
        id="password"
        label="Password"
        error={!!passwordError}
        helperText={passwordError ?? ''}
        value={password}
        onChange={event => setPassword(event.target.value)}
        type="password"
        autoComplete="current-password"
        variant="outlined"
        margin="normal"
        required
        fullWidth
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={e => e.preventDefault() || handleSubmit()}
      >
        Sign In
      </Button>
      <Link component={RouterLink} to={`${routes.forgotPassword}`} variant="body2">
        Forgot password?
      </Link>
      <br />
      <Link component={RouterLink} to={`${routes.resetPassword}`} variant="body2">
        Have a password reset code?
      </Link>
      <Snackbar open={errorOpen} autoHideDuration={4000} onClose={() => setErrorOpen(false)}>
        <Alert onClose={() => setErrorOpen(false)} severity="error">
          Sorry your login details are incorrect, please try again
        </Alert>
      </Snackbar>
      {loading ? <LinearProgress className={classes.progress}/> : null}
    </form>
  );
}

Login.propTypes = {
  routes: PropTypes.object,
  onLogin: PropTypes.func
};

export default Login;
