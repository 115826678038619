import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import AdditionalFields from './AdditionalFields';
import AppointmentFields from './AppointmentFields';
import BookingFields from './BookingFields';
import IssueFields from './IssueFields';
import PricingFields from './PricingFields';

const VisitForm = forwardRef(({visit = {}, job, fieldSet = 'booking', onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    },

    getData() {
      const data = formRef.current.getData();
      const prefix = `visit${visit.id ?? ''}__`;
      const noPrefix = {};
      for (let key of Object.keys(data)) {
        noPrefix[key.replace(prefix, '')] = data[key];
      }
      return VisitForm[fieldSet].getData(noPrefix);
    }
  }));

  const handleFields = useCallback((props) => {
    const Fields = VisitForm[fieldSet].fieldsComponent;
    return <Fields {...props} job={job}/>;
  }, [job, fieldSet]);

  const handleSave = useCallback((data) => {
    const update = VisitForm[fieldSet].getData(data);
    if (job) {
      update.job_id = job.id;
    }
    return services.visit.saveVisit(update);
  }, [services, job, fieldSet]);

  return (
    <BaseForm
      ref={formRef}
      entity={visit}
      type="visit"
      fieldsComponent={handleFields}
      onSave={handleSave}
      onSaved={onSaved}
    />
  );
});

VisitForm.booking = {
  fieldsComponent: BookingFields,
  getData: ({id, job_id, user_id, address, postcode, date, time, units, special_requirements, confirmed, notes}) => ({
    id,
    job_id,
    user_id,
    address,
    postcode,
    date,
    time,
    units,
    special_requirements: time === 'Other' ? special_requirements : null,
    confirmed,
    notes
  })
}

VisitForm.appointment = {
  fieldsComponent: AppointmentFields,
  getData: (
    {
      batch_number,
      environment_id,
      condition_id,
      maintenance_id,
      repairs_completed,
      outcome_id,
      time_taken,
      signed_off,
      sample_obtained
    }
  ) => ({
    batch_number,
    environment_id,
    condition_id,
    maintenance_id,
    repairs_completed,
    outcome_id,
    time_taken,
    signed_off,
    sample_obtained
  })
}

VisitForm.issues = {
  fieldsComponent: IssueFields,
  getData: ({findings, summary, parts_required, follow_up}) => ({findings, summary, parts_required, follow_up})
}

VisitForm.additional = {
  fieldsComponent: AdditionalFields,
  getData: ({pricing_list}) => ({pricing_list})
}

VisitForm.pricing = {
  fieldsComponent: PricingFields,
  getData: (
    {
      is_appointment_missed,
      inspection_cost,
      repair_cost,
      work_cost,
      missed_appointment_cost,
      estimated_labour_cost,
      estimated_parts_cost
    }
  ) => ({
    is_appointment_missed,
    inspection_cost,
    repair_cost,
    work_cost,
    missed_appointment_cost,
    estimated_labour_cost,
    estimated_parts_cost
  })
}

export default VisitForm;
