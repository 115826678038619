import {name} from '@management-ui/core';
import {Box, Paper, styled} from '@mui/material';
import moment from 'moment';
import qs from 'qs';
import * as React from 'react';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {WeeklySlotCalendar} from '../../../../@management-ui/core';
import {ServiceContext} from '../../../../components/Services';
import Slot from './Slot';
import UserSelector from './UserSelector';

const Events = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  width: '100%',
}));

const COMPARE_DATE_FORMAT = 'YYYY-MM-DD';

const Calendar = ({onSelectDay = () => null, onSelectVisit = () => null}) => {
  const services = useContext(ServiceContext);

  const usersLoaded = useRef(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (!usersLoaded.current) {
      usersLoaded.current = true;
      services.user.getUsers({filter: {is_technician: '1', is_active: '1'}}, 1, 500).then(response => setUsers(response.data.map(u => ({
        id: u.id,
        name: name(u)
      })))).catch(() => setUsers([]));
    }
  }, [services]);

  const {pathname} = useLocation();
  const history = useHistory();

  const [week, setWeek] = useState(null);
  const initialised = useRef(false);
  const loaded = useRef('');

  const [selectedUsers, setSelectedUsers] = useState([]);

  const [filters, setFilters] = useState(null);

  const [loading, setLoading] = useState(false);
  const loadingCount = useRef(0);

  const handleLoading = useCallback((isLoading) => {
    loadingCount.current += (isLoading ? 1 : -1);
    setLoading(loadingCount.current > 0);
  }, []);

  const [visits, setVisits] = useState([]);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (!initialised.current) {
      initialised.current = true;
      if (window.location.search) {
        const params = qs.parse(window.location.search, {ignoreQueryPrefix: true});
        if (params.week) {
          setWeek(moment(params.week));
          history.replace(pathname);
          return;
        }
      }
      setWeek(moment().startOf('isoWeek'));
    }
  }, [history, pathname]);

  useEffect(() => {
    if (week && selectedUsers.length) {
      setFilters({
        filter: {
          confirmed: '1',
          range: [
            week.clone().startOf('isoWeek'),
            week.clone().endOf('isoWeek')
          ].map(d => d.format(COMPARE_DATE_FORMAT)).join('|'),
          users: selectedUsers.join('|')
        }
      });
    }
  }, [week, selectedUsers]);

  useEffect(() => {
    if (filters) {
      const key = JSON.stringify(filters);
      if (key !== loaded.current) {
        loaded.current = key;
        handleLoading(true);
        services.visit.getVisits(filters, 1, 1000).then(retrieved => {
          handleLoading(false);
          setVisits(retrieved.data)
        }).catch(() => {
          handleLoading(false);
          setVisits([])
        });
      }
    }
  }, [services, filters, handleLoading]);

  useEffect(() => {
    setEvents(visits.map(visit => ({
      visit: visit,
      when: {
        date: moment(visit.date)
      }
    })));
  }, [visits]);


  return week ? (
    <>
      <UserSelector
        users={users}
        selected={selectedUsers}
        onChange={setSelectedUsers}
      />
      <Paper
        component={Box}
        marginTop={2}
        style={loading ? {opacity: 0.5} : {}}
        flex={1}
        display="flex"
        flexDirection="column"
      >
        <WeeklySlotCalendar
          week={week}
          events={events}
          onSelectWeek={setWeek}
          onSelectDay={onSelectDay}
          onRenderDay={(day, events) => (
            <Events>
              {['AM', '10-2', 'PM', 'Other'].map((slot, slotIndex) => (
                <Slot
                  key={slotIndex}
                  events={events}
                  slot={slot}
                  slotIndex={slotIndex}
                  onSelectSlot={() => onSelectDay({date: day, time: slot})}
                  onSelectVisit={onSelectVisit}
                  day={day}
                  filters={filters}
                  onVisitsUpdated={setVisits}
                />
              ))}
            </Events>
          )}
        />
      </Paper>
    </>
  ) : null;
}

export default Calendar;
