import {ConfirmationDialog, FormDialog} from '@management-ui/core';
import {Link, LinkOff} from '@mui/icons-material';
import {IconButton, Tooltip} from '@mui/material';
import * as React from 'react';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {ServiceContext} from '../../../../../components/Services';
import LinkPartForm from '../../../forms/LinkPartForm';
import useTableStyles from '../tableStyles';

const Parts = ({visit = null}) => {
  const classes = useTableStyles();
  const services = useContext(ServiceContext);
  const [showLink, setShowLink] = useState(false);
  const [showUnlink, setShowUnlink] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [parts, setParts] = useState([]);
  const initialised = useRef(false);

  useEffect(() => {
    if (visit && !initialised.current) {
      initialised.current = true;
      setLoading(true);
      services.visit.getParts(visit).then(list => {
        setParts(list);
        setLoading(false);
        setLoaded(true);
      }).catch(() => {
        setLoading(false);
        setLoaded(true);
      });
    }
  }, [services, visit]);

  const handleUnlink = useCallback((confirmed) => {
    setShowUnlink(false);
    if (confirmed) {
      setLoading(true);
      services.visit.togglePart(visit, selected).then(list => {
        setLoading(false);
        setParts(list);
      }).catch(() => setLoading(false));
    }
  }, [services, visit, selected]);

  return (
    <div className={[classes.container, loading ? classes.loading : ''].join(' ')}>
      <div className={classes.actions}>
        <Tooltip title="Link Part from Job">
          <IconButton onClick={() => setSelected(null) || setShowLink(true)}>
            <Link/>
          </IconButton>
        </Tooltip>
      </div>
      <div className={classes.row}>
        <div className={classes.header}>Name</div>
        <div className={classes.header}>Quantity</div>
        <div className={classes.action}/>
      </div>
      {loaded ? (parts.length > 0 ? (
        parts.map((part, index) => (
          <div key={index} className={classes.row}>
            <div className={classes.column}>{part.name}</div>
            <div className={classes.column}>{part.quantity}</div>
            <div className={classes.action}>
              <Tooltip title="Unlink Part">
                <IconButton onClick={() => setSelected(part) || setShowUnlink(true)}>
                  <LinkOff/>
                </IconButton>
              </Tooltip>
            </div>
          </div>
        ))
      ) : (
        <div className={classes.noRecords}>There are no parts associated with this visit</div>
      )) : null}
      <FormDialog
        title="Link Part from Job"
        open={showLink}
        maxWidth="sm"
        onClose={() => setShowLink(false)}
        render={(props) => (
          <LinkPartForm
            {...props}
            part={selected ?? {}}
            visit={visit}
            existing={parts}
            onSaved={(parts) => setShowLink(false) || setParts(parts)}
          />
        )}
      />
      <ConfirmationDialog
        title="Unlink Part"
        message="Are you sure you want to unlink this part from this visit?"
        open={showUnlink}
        onClose={handleUnlink}
      />
    </div>
  );
};

export default Parts;
