import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const NotificationForm = forwardRef(({notification, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={notification}
      type="notification"
      fieldsComponent={(props) => <Fields {...props}/>}
      onSave={({id, title, body}) => services.notification.saveNotification({
        id,
        title,
        body
      })}
      onSaved={onSaved}
    />
  );
});

export default NotificationForm;
