import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const PartForm = forwardRef(({visit, existing, part = {}, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={part}
      type="part"
      fieldsComponent={(props) => <Fields visit={visit} existing={existing} {...props}/>}
      onSave={({part_id}) => services.visit.togglePart(visit, {id: part_id})}
      onSaved={onSaved}
    />
  );
});

export default PartForm;
