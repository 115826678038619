import * as qs from 'qs';
import {API} from './API';

export class NotificationService {
  async getTableNotifications(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getNotifications(query, page, length);
  }

  async getNotifications(query, page, length) {
    return (await API.getConnection())
      .get('notifications', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getNotification(id, archive = false) {
    return (await API.getConnection())
      .get(`notifications/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveNotification(data) {
    let method = 'post';
    let url = 'notifications';
    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async sendNotification(notification) {
    return (await API.getConnection())
      .post(`notifications/${notification.id}`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteNotification(notification) {
    return (await API.getConnection())
      .delete(`notifications/${notification.id}`)
      .then(() => null)
      .catch(error => API.handleError(error));
  }
}
