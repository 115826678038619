import {styled} from '@mui/material';
import React, {useEffect} from 'react';
import {useUserStates} from '../../../../hooks';

const Container = styled('div')(({theme}) => ({
  borderBottom: `2px solid ${theme.palette.grey['100']}`,
  borderTop: `2px solid ${theme.palette.grey['100']}`,
  display: 'flex',
  flex: 1,
  width: '100%',

  '& > span': {
    alignItems: 'center',
    display: 'flex',
    flex: 0,
    padding: `0 ${theme.spacing(2)}`,
  }
}));

const States = styled('ul')(({theme}) => ({
  display: 'flex',
  flex: 1,
  listStyle: 'none',
  margin: 0,
  padding: `0 ${theme.spacing(2)} 0 0`,
}));

const State = styled('li')(({theme}) => ({
  background: 'var(--state-background-colour)',
  borderLeft: `2px solid ${theme.palette.grey['100']}`,
  color: 'var(--state-text-colour)',
  cursor: 'pointer',
  fontWeight: '700',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,

  '&:last-of-type': {
    borderRight: `2px solid ${theme.palette.grey['100']}`,
  },

  '&.selected': {
    background: 'var(--state-text-colour)',
    color: 'var(--state-background-colour)',
  }
}));

const Filter = ({state, onChangeState}) => {
  const states = useUserStates();

  useEffect(() => console.log({state}), [state]);

  return (
    <Container>
      <span>Displaying:</span>
      <States>
        {states.map(({title, key, textColour, backgroundColour}, index) => (
          <State
            key={index}
            className={state === key ? 'selected' : ''}
            style={{'--state-text-colour': textColour, '--state-background-colour': backgroundColour}}
            onClick={() => state === key ? null : onChangeState(key)}
          >
            {title}
          </State>
        ))}
      </States>
    </Container>
  );
}

export default Filter;
