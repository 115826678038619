import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import moment from '../../moment';
import {compareDateFormat} from '../../vars';

const useStyles = makeStyles(theme => {
  const border = `1px solid ${theme.palette.grey['300']}`;
  return {
    container: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      width: '100%',
    },

    name: {
      flex: 0,
      fontSize: '1.2em',
      fontWeight: 500,
      margin: 0,
      opacity: 0.5,
      padding: theme.spacing(2),
      textAlign: 'center',
    },

    slot: {
      borderTop: border,
      display: 'flex',
      flex: 1,
    },

    today: {
      '& h3': {
        background: theme.palette.grey['300'],
        fontWeight: 700,
      }
    },

    hasHighlights: {
      slot: {
        backgroundColor: theme.palette.grey['500'],
      },
    },

    highlighted: {
      backgroundColor: theme.palette.common.white,
    },

    time: {
      flex: '0 0 72px',
      opacity: 0.5,
      padding: theme.spacing(2),
      width: 72
    },

    events: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      opacity: 0.5,
    },

    event: {
      alignItems: 'center',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.background.paper,
      display: 'flex',
      flex: 1,
      fontSize: '0.7em',
      justifyContent: 'center',
      padding: theme.spacing(0.5),
    },

    available: {

      '& $name': {
        opacity: 1,
      },
      '& $time': {
        opacity: 1,
      },
      '& $events': {
        opacity: 1,
      }
    }
  };
});

const Day = (
  {
    day,
    events = [],
    highlights = [],
    hasHighlights = false,
    onSelectSlot,
    onSelectEvent,
    onRenderEvents,
    onRenderDay
  }
) => {
  const classes = useStyles();
  const start = day.set({hour: 6, minute: 0, second: 0, millisecond: 0});
  const available = start.diff(moment().set({hour: 6, minute: 0, second: 0, millisecond: 0}), 'days') >= 0

  const getEventOverlaps = useCallback((slot) => {
    const end = slot.clone().add(30, 'minutes').subtract(1, 'second');
    return events.filter(e => moment.range(e.when.start, e.when.end).contains(end));
  }, [events]);

  const getHighlightOverlaps = useCallback((slot) => (
    highlights.filter(h => moment.range(h.when.start, h.when.end.clone().subtract(1, 'minute')).contains(slot))
  ), [highlights]);

  const renderEvents = useCallback((slot) => {
    const overlaps = getEventOverlaps(slot);
    return onRenderEvents ? onRenderEvents(overlaps, slot) : (
      <div className={classes.events}>
        {overlaps.map((event, index) => (
          <div
            key={index}
            className={classes.event}
            style={event.colour ? {backgroundColor: event.colour} : {}}
            onClick={(e) => {
              e.stopPropagation();
              if (available) {
                onSelectEvent(event);
              }
            }}>
            {slot.diff(event.when.start, 'minutes') === 0 ? event.title : ''}
          </div>
        ))}
      </div>
    );
  }, [classes, onRenderEvents, onSelectEvent, getEventOverlaps, available]);

  return (
    <div className={[
      classes.container,
      hasHighlights ? classes.hasHighlights : '',
      day.format(compareDateFormat) === moment().format(compareDateFormat) ? classes.today : '',
      available ? classes.available : ''
    ].join(' ')}>
      <h3
        className={classes.name}>{day.format('ddd Do')}</h3>
      {onRenderDay ? (
        <div className={classes.slot}>
          {onRenderDay(day, events)}
        </div>
      ) : [...Array(28).keys()].map((s, index) => {
        const slot = start.clone().add(s * 30, 'minutes');
        return (
          <div
            key={index}
            className={`${classes.slot} ${getHighlightOverlaps(slot).length ? classes.highlighted : ''}`}
            onClick={() => {
              if (available && onSelectSlot) {
                onSelectSlot(day, slot, getEventOverlaps(slot), getHighlightOverlaps(slot));
              }
            }}>
            <span className={classes.time}>{slot.format('k:mm')}</span>
            {renderEvents(slot)}
          </div>
        )
      })}
    </div>
  )
};

Day.propTypes = {
  day: PropTypes.object,
  events: PropTypes.array,
  highlights: PropTypes.array,
  hasHighlights: PropTypes.bool,
  onSelectSlot: PropTypes.func,
  onSelectEvent: PropTypes.func,
  onRenderEvents: PropTypes.func
};

export default Day;
