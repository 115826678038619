import {Panes} from '@management-ui/core';
import {UploadsField} from '@management-ui/upload';
import React from 'react';

const Fields = ({image = {}, prefix = ''}) => {
  return (
    <Panes
      entity={image}
      prefix={prefix}
      panes={[
        {
          title: 'Image Details',
          fields: [
            <UploadsField
              name="images"
              prefix={prefix}
              label="Drop images here or click to select from your computer"
              rules={{required: 'Please select at least one image to upload'}}
              types=".jpg,.jpeg,.png"
            />
          ]
        }
      ]}
      title=""/>
  );
};

export default Fields;
