import {useErrors} from '@management-ui/core';
import {reverse} from 'named-urls';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useRouteMatch} from 'react-router';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import Wrapper from './Wrapper';

const withJob = (JobComponent, linkJobCrumb) => (props) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const match = useRouteMatch();

  const {errors, toggleError} = useErrors(
    useMemo(() => ({job: 'Sorry the job could not be accessed'}), [])
  );

  const idRef = useRef('');
  const [loading, setLoading] = useState(false);
  const [job, setJob] = useState(null);

  const loadJob = useCallback((id) => {
    if (idRef.current !== `${id}`) {
      idRef.current = `${id}`;
      setLoading(true);
      services.job.getJob(id).then(retrieved => {
        setJob(retrieved);
        setLoading(false);
      }).catch(() => {
        toggleError('job', true);
        setLoading(false);
      });
    }
  }, [services, toggleError]);

  useEffect(() => {
    if (match.params?.id) {
      loadJob(match.params?.id);
    }
  }, [history, match, job, loadJob]);

  const [title, setTitle] = useState('Loading');
  const [crumbs, setCrumbs] = useState([...Wrapper.standardCrumbs]);

  useEffect(() => {
    let header = 'Loading';
    let trail = [...Wrapper.standardCrumbs];
    if (job) {
      header = `Job Details - ${job.reference}`;
      trail.push({title: job.reference, link: linkJobCrumb ? reverse(routes.jobs.detail, {id: job.id}) : ''});
    }
    setTitle(header);
    setCrumbs(trail);
  }, [job]);

  return (
    <JobComponent {...{
      job,
      setJob,
      loadJob,
      title,
      setTitle,
      crumbs,
      setCrumbs,
      loading,
      onLoading: setLoading,
      errors,
      toggleError,
      ...props
    }} />
  );
};

export default withJob;
