import {
  ConfirmationDialog,
  dateTime,
  DetailColumns,
  DetailPane,
  FormDialog,
  useDialogs,
  useErrors
} from '@management-ui/core';
import {Delete, Edit, Send} from '@mui/icons-material';
import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useRouteMatch} from 'react-router';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import NotificationForm from '../../forms/NotificationForm';
import Wrapper from './Wrapper';

const Notification = () => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const match = useRouteMatch();

  const {errors, toggleError} = useErrors(
    useMemo(() => ({notification: 'Sorry the notification could not be accessed'}), [])
  );

  const {openDialogs, toggleDialog} = useDialogs(['edit', 'send', 'delete']);

  const idRef = useRef('');
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(null);

  const loadNotification = useCallback((id) => {
    if (idRef.current !== `${id}`) {
      idRef.current = `${id}`;
      setLoading(true);
      services.notification.getNotification(id).then(retrieved => {
        setNotification(retrieved);
        setLoading(false);
      }).catch(() => {
        toggleError('notification', true);
        setLoading(false);
      });
    }
  }, [services, toggleError]);

  useEffect(() => {
    if (match.params?.id) {
      loadNotification(match.params?.id);
    }
  }, [match, notification, loadNotification]);

  const [title, setTitle] = useState('Loading');
  const [crumbs, setCrumbs] = useState([...Wrapper.standardCrumbs]);

  useEffect(() => {
    let header = 'Loading';
    let trail = [...Wrapper.standardCrumbs];
    if (notification) {
      header = notification.title;
      trail.push({title: header, link: ''});
    }
    setTitle(header);
    setCrumbs(trail);
  }, [notification]);

  const handleSaved = useCallback((updated) => {
    toggleDialog('edit', false);
    if (updated) {
      setNotification(updated);
    } else if (notification?.id) {
      loadNotification(notification.id);
    }
  }, [toggleDialog, setNotification, loadNotification, notification]);

  const handleSend = useCallback((confirmed) => {
    toggleDialog('send', false);
    if (confirmed) {
      setLoading(true);
      services.notification.sendNotification(notification).then((sent) => {
        setLoading(false);
        setNotification(sent);
      }).catch(() => setLoading(false));
    }
  }, [services, toggleDialog, notification]);

  const handleDelete = useCallback((confirmed) => {
    toggleDialog('delete', false);
    if (confirmed) {
      setLoading(true);
      services.notification.deleteNotification(notification).then(() => {
        setLoading(false);
        history.push(routes.notifications.index);
      }).catch(() => setLoading(false));
    }
  }, [services, history, toggleDialog, notification]);

  return (
    <Wrapper title={title} notification={notification} loading={loading} crumbs={crumbs} errors={errors}>
      {notification?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Notification Details"
              actions={[
                ...(notification['sent_at'] ? [] : [
                  {title: 'Edit Notification', icon: <Edit/>, onClick: () => toggleDialog('edit', true)},
                  {title: 'Send Notification', icon: <Send/>, onClick: () => toggleDialog('send', true)}
                ]),
                {title: 'Delete Notification', icon: <Delete/>, onClick: () => toggleDialog('delete', true)}
              ]}
              details={[
                {title: 'Title', value: notification.title},
                {title: 'Body', value: notification.body},
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit Notification"
                    open={openDialogs['edit'] ?? false}
                    onClose={() => toggleDialog('edit', false)}
                    render={(props) => (
                      <NotificationForm
                        {...props}
                        notification={notification}
                        onSaved={handleSaved}/>
                    )}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    title="Send Notification"
                    message="Are you sure you want to send this notification?"
                    open={openDialogs['send'] ?? false}
                    onClose={handleSend}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    title="Delete Notification"
                    message="Are you sure you want to delete this notification?"
                    open={openDialogs['delete'] ?? false}
                    onClose={handleDelete}
                  />
                )
              ]}
            />,

            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Sent', value: notification.sent_at ? dateTime(notification.sent_at) : 'Not Yet Sent'},
                {title: 'Created', value: dateTime(notification.created_at)},
                {title: 'Last Updated', value: dateTime(notification.updated_at)}
              ]}
            />,
          ]}/>
        </>
      ) : null}
    </Wrapper>
  );
};

export default Notification;
