import {useFloatingButtons} from '@management-ui/core';
import FloatingButtons from '@management-ui/core/components/FloatingButtons';
import SaveIcon from '@mui/icons-material/Save';
import {Box, Paper, Typography} from '@mui/material';
import {useTheme} from '@mui/styles';
import React, {useCallback, useContext, useMemo, useRef, useState} from 'react';
import Navigation from '../../../../components/Navigation';
import {ServiceContext} from '../../../../components/Services';
import JobInformationForm from '../../forms/JobInformationForm';
import DetailTab from './DetailTab';
import JobFiles from './JobFiles';

const TABS = [
  {
    id: 'information',
    menuTitle: 'Job Information',
  },
  {
    id: 'files',
    menuTitle: 'Files',
  },
];

const JobDetails = ({job, onJobUpdated}) => {
  const services = useContext(ServiceContext);
  const theme = useTheme();
  const [selected, setSelected] = useState('information')
  const {buttons, setButtonState} = useFloatingButtons(useMemo(() => ({
    save: {
      colour: 'secondary',
      states: {
        default: {
          label: 'Save',
          icon: SaveIcon,
          spinning: false
        },
        saving: {
          label: 'Saving...',
          spinning: true
        },
        loading: {
          label: 'Loading...',
          spinning: true
        }
      }
    }
  }), []));
  /** @type {({current: JobInformationForm})} */
  const informationRef = useRef();

  const handleSave = useCallback(() => {
    const update = {
      id: job.id,
      ...(informationRef.current.getData())
    };
    setButtonState('save', 'saving');
    services.job.saveJob(update).then((updated) => {
      onJobUpdated(updated);
      setButtonState('save', 'default');
    }).catch(() => {
      setButtonState('save', 'default');
    });
  }, [services, setButtonState, job, onJobUpdated]);

  return selected ? (
    <Paper component={Box} marginTop={1} width="100%" overflow="hidden">
      <Box padding={2}>
        <Typography variant="subtitle2" color={theme['palette'].grey['600']}>Job Details</Typography>
      </Box>
      <Navigation
        items={TABS}
        current={selected}
        onSelect={({id}) => setSelected(id)}
      >
        <DetailTab title="Job Information" visible={selected === 'information'}>
          <JobInformationForm
            ref={informationRef}
            job={job}
          />
        </DetailTab>
        <DetailTab title="Files" visible={selected === 'files'}>
          <JobFiles job={job}/>
        </DetailTab>
      </Navigation>
      {job.completed ? null : <FloatingButtons buttons={buttons} onClick={handleSave}/>}
    </Paper>
  ) : null;
};

export default JobDetails;
