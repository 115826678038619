import {Panes, TextField} from '@management-ui/core';
import React from 'react';

export default function Fields(
  {
    client = {},
    prefix = '',
    multiplePanes = true,
    children
  }
) {
  return (
    <Panes
      entity={client}
      prefix={prefix}
      panes={[
        {
          title: 'Contact Details',
          fields: [
            <TextField
              name="name"
              prefix={prefix}
              label="Company Name"
              rules={{required: 'Please enter a company name'}}
            />,
            <TextField
              name="contact"
              prefix={prefix}
              label="Main Contact"
            />,
            <TextField
              name="phone"
              prefix={prefix}
              label="Telephone Number"
            />,
            <TextField
              name="email"
              prefix={prefix}
              label="Email Address"
            />,
            <TextField
              name="website"
              prefix={prefix}
              label="Website"
            />,
            <TextField
              name="store_number"
              prefix={prefix}
              label="Store Number (if applicable)"
            />
          ]
        }, {
          title: 'Notes',
          fields: [
            <TextField
              name="notes"
              prefix={prefix}
              label="Notes"
              fieldProps={{
                multiline: true,
                rows: 5
              }}
            />,
          ]
        }
      ]}
      title={multiplePanes ? null : 'Client Details'}
    >{children}</Panes>
  );
}
