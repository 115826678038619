import {Paper, Switch} from '@mui/material';
import {styled} from '@mui/styles';
import * as React from 'react';
import {useCallback, useEffect} from 'react';

const Users = styled('div')(({theme}) => ({
  display: 'flex',
  flexWrap: 'wrap',
  margin: `${theme.spacing(1)} -8px`,
}));

const User = styled('div')(({theme}) => ({
  display: 'flex',
  flex: '0 0 25%',
  padding: theme.spacing(1),
  width: '25%',

  '& .MuiPaper-root': {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    opacity: 0.4,
    overflow: 'hidden',
    padding: [1, 1, 1, 2].map(p => `${theme.spacing(p)}`).join(' '),
    transition: '0.25s opacity ease-in-out',
    width: '100%',

    '&:hover': {
      opacity: 0.75,
    },

    '&.selected': {
      opacity: 1,

      '&:hover': {
        opacity: 1,
      }
    }
  }
}));

const KEY = 'selectedUsers';

const UserSelector = ({users, selected, onChange}) => {

  useEffect(() => {
    const cached = localStorage.getItem(KEY);
    if (cached) {
      onChange(cached.split(',').map(id => parseInt(id)));
    } else {
      onChange(users.map(e => e.id));
    }
  }, [users, onChange]);

  const handleSelect = useCallback((user) => {
    const update = [...selected].filter(id => `${id}`.match(/\d{1,10}/g));
    const index = update.indexOf(user.id);
    if (index >= 0) {
      update.splice(index, 1);
    } else {
      update.push(user.id);
    }
    const selection = [...update];
    localStorage.setItem(KEY, selection.join(','));
    onChange(selection);
  }, [selected, onChange]);

  return (
    <Users>
      {users.map((user, index) => {
        const checked = selected.indexOf(user.id) >= 0;
        return (
          <User key={index}>
            <Paper onClick={() => handleSelect(user)} className={checked ? 'selected' : ''}>
              {user.name}
              <Switch
                color="secondary"
                checked={checked}
              />
            </Paper>
          </User>
        )
      })}
    </Users>
  );
};

export default UserSelector;
