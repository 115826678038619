import {Paper, styled, Typography} from '@mui/material';
import {reverse} from 'named-urls';
import React, {useEffect, useMemo, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import Switched from '../../../../components/Switched';
import Switcher from '../../../../components/Switcher';
import routes from '../../../../routes';
import VisitsTable from '../Visits/Table';
import JobDetails from './JobDetails';
import Parts from './Parts';

const Container = styled(Paper)(({theme}) => ({
  '&.MuiPaper-root': {
    overflow: 'hidden',
    marginTop: theme.spacing(1),

    '&.padded': {
      padding: theme.spacing(2),
    },
  },

  '& > .MuiTypography-subtitle2': {
    color: theme.palette.grey['600']
  },
}));

const Tabs = ({job, onJobUpdated}) => {
  const match = useRouteMatch();
  const [tab, setTab] = useState('loading');

  const tabs = useMemo(() => [
    {
      title: 'Details',
      slug: '',
      path: reverse(routes.jobs.detail, {
        id: job?.id ?? 0
      })
    },
    {
      title: 'Parts',
      slug: 'parts',
      path: reverse(routes.jobs.tab, {
        id: job?.id ?? 0,
        tab: 'parts'
      })
    },
    {
      title: 'Visits',
      slug: 'visits',
      path: reverse(routes.jobs.tab, {
        id: job?.id ?? 0,
        tab: 'visits'
      })
    }
  ], [job]);

  useEffect(() => {
    if (match.params?.tab && tabs.findIndex(t => t.slug) >= 0) {
      setTab(match.params?.tab);
    } else {
      setTab('');
    }
  }, [match, tabs]);

  return (
    <>
      <Switcher tabs={tabs}/>
      <Switched tab="" currentTab={tab}>
        <JobDetails job={job} onJobUpdated={onJobUpdated}/>
      </Switched>
      <Switched tab="parts" currentTab={tab}>
        <Container className="padded">
          <Typography variant="subtitle2">Parts</Typography>
          <Parts job={job}/>
        </Container>
      </Switched>
      <Switched tab="visits" currentTab={tab}>
        <Container>
          <VisitsTable job={job}/>
        </Container>
      </Switched>
    </>
  );
};

export default Tabs;
