import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const JobDetailsForm = forwardRef(({job, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={job}
      type="job"
      fieldsComponent={(props) => <Fields {...props}/>}
      onSave={({id, client_id, type_id, reference, customer, address, postcode, phone, email}) => services.job.saveJob({
        id,
        client_id,
        type_id,
        reference,
        customer,
        address,
        postcode,
        phone,
        email
      })}
      onSaved={onSaved}
    />
  );
});

export default JobDetailsForm;
