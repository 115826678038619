import {Box, Typography} from '@mui/material';
import {useTheme} from '@mui/styles';
import React from 'react';

const DetailTab = ({title = '', visible = false, children}) => {
  const theme = useTheme();
  return (
    <Box
      borderTop={`4px solid ${theme['palette'].grey['100']}`}
      display={visible ? 'block' : 'none'}
      paddingX={2}
      paddingBottom={2}
    >
      <Box paddingTop={2} paddingX={1}>
        <Typography
          variant="h6"
          color={theme['palette'].primary.main}
        >{title}</Typography>
        {children}
      </Box>
    </Box>
  );
};

export default DetailTab;

