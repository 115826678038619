import * as React from 'react';
import {useState} from 'react';
import Attribute from './Attribute';
import Navigation from '../../../../components/Navigation';

const ATTRIBUTES = [
  {
    id: 'jobTypes',
    menuTitle: 'Job Types',
  },
  {
    id: 'environments',
    menuTitle: 'Liabilities',
  },
  {
    id: 'furnitureConditions',
    menuTitle: 'Condition Ratings',
  },
  {
    id: 'maintenances',
    menuTitle: 'Maintenance Requirements',
  },
  {
    id: 'appointmentOutcomes',
    menuTitle: 'Appointment Outcomes'
  }
];

const Attributes = () => {
  const [selected, setSelected] = useState('jobTypes')

  return selected ? (
    <Navigation
      items={ATTRIBUTES}
      current={selected}
      onSelect={({id}) => setSelected(id)}
    >
      {selected === 'jobTypes' ? (
        <Attribute
          singularName="Job Type"
          pluralName="Job Types"
          serviceName="jobType"
        />
      ) : selected === 'environments' ? (
        <Attribute
          singularName="Liability"
          pluralName="Liabilities"
          serviceName="environment"
        />
      ) : selected === 'furnitureConditions' ? (
        <Attribute
          singularName="Condition Rating"
          pluralName="Condition Ratings"
          serviceName="furnitureCondition"
        />
      ) : selected === 'maintenances' ? (
        <Attribute
          singularName="Maintenance Requirement"
          pluralName="Maintenance Requirements"
          serviceName="maintenance"
        />
      ) : (
        <Attribute
          singularName="Appointment Outcome"
          pluralName="Appointment Outcomes"
          serviceName="appointmentOutcome"
        />
      )}
    </Navigation>
  ) : null;
};

export default Attributes;
