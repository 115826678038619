import {prefixWithSeparator} from '@management-ui/core';
import {FormControl} from '@mui/material';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React, {forwardRef, useCallback, useImperativeHandle, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {Controller, useFormContext} from 'react-hook-form';

export const useStyles = makeStyles(theme => ({
  dropzone: {
    border: `1px dashed ${theme.palette.divider}`,
    flex: 1,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    minHeight: '50px',
    padding: theme.spacing(1),
    textAlign: 'center',

    '& p': {
      margin: 0,
      padding: `${theme.spacing(2)} 0`,
    }
  },

  error: {
    color: theme.palette.error.main,
  },
}));

/**
 * A dropzone files upload field for use within the `BaseForm` component.
 *
 *
 * **Methods:**
 *
 * `getFiles() : File[]` - Retrieve the currently selected files
 *
 * @module UploadField
 *
 * @param {string} name The name for field
 * @param {string} prefix The prefix applied to the form data
 * @param {string} label The label to display on the field
 * @param {?string} id The ID of the field
 * @param {object} rules The validation rules for the field
 * @param {string} types A comma-separated list of the accept file extensions
 * @param {object} fieldProps Any additional props for the [Dropzone](https://github.com/react-dropzone/react-dropzone)
 *
 * @example
 * <UploadsField
 *   name="files"
 *   prefix={prefix}
 *   label="Please at least one file to upload"
 *   types=".pdf,.docx,.doc"
 *   rules={{required: 'Please select at least one document'}}
 * />
 *
 */
const UploadField = forwardRef((
  {
    name,
    prefix = '',
    label,
    id = null,
    rules = {},
    types = '.jpg,.png,.gif',
    fieldProps
  }, ref
) => {
  const classes = useStyles();
  const {control, formState: {errors}, setValue} = useFormContext();
  const prefixedName = `${prefixWithSeparator(prefix)}${name}`;

  const [files, setFiles] = useState([]);

  const {getRootProps, getInputProps} = useDropzone(/** @type {Partial} */{
    accept: types,
    onDrop: useCallback(acceptedFiles => {
      if (acceptedFiles.length > 0) {
        const uploads = [];
        for (let file of acceptedFiles) {
          uploads.push(file);
        }
        setFiles(uploads);
        setValue(prefixedName, uploads);
      } else {
        setValue(prefixedName, []);
      }
    }, [setValue, prefixedName]),
    ...fieldProps
  });

  useImperativeHandle(ref, () => ({
    getFiles() {
      return files;
    }
  }));

  return (
    <FormControl
      variant="outlined"
      required={rules && !!rules.required}
      fullWidth>
      <Controller
        name={prefixedName}
        control={control}
        rules={rules}
        render={() => (
          <div {...getRootProps({className: classes.dropzone, multiple: false})} id={id ?? prefixedName}>
            <input {...getInputProps()} />
            <p>{label}</p>
            {files.length ? <p>Selected: {files.map(f => f.name).join(', ')}</p> : null}
            {!!errors[prefixedName] ? <p className={classes.error}>{errors[prefixedName].message}</p> : null}
          </div>
        )}
      />
    </FormControl>
  );
});

UploadField.propTypes = {
  name: PropTypes.string,
  prefix: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  rules: PropTypes.object,
  types: PropTypes.string,
  fieldProps: PropTypes.object
};

export default UploadField;
