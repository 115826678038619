import MaterialTable, {MTableToolbar} from '@material-table/core';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Snackbar
} from '@mui/material';
import PropTypes from 'prop-types';
import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react';

/**
 * Component for showing a table of records using @material-table/core. For props passed directly to
 * @material-table/core see that module's docs for details.
 *
 * @module DataTable
 *
 * @param {string} title Title for the table.
 * @param {object} options Additional options (reference [material-table](https://material-table-core.com/) docs).
 * @param {object} components Additional components (reference [material-table](https://material-table-core.com/) docs).
 * @param {object} columns Columns displayed within the table (reference [material-table](https://material-table-core.com/) docs).
 * @param {function} loadData A callback which is passed the query object from the table. Should return records to be displayed.
 * @param {array} data Used to directly populate the table with data.
 * @param {function} onRowClick A callback to handle when a row is clicked (reference [material-table](https://material-table-core.com/) docs).
 * @param {boolean} canRefresh Determine whether the table should include a 'Refresh' button (default: true).
 * @param {object} actions Add actions before or after default ones added by DataTable. Should be an object with keys 'before' or 'after' passing array of actions (reference [material-table](https://material-table-core.com/) docs).
 * @param {object} newForm An object defining the 'new record' form. Providing this value will result in the 'Add New' action being included by default.
 * @param {object} editForm An object defining the 'edit record' form. Providing this value will result in the edit form being included in the pane.
 * @param {object} tableProps Any additional props to pass to the [material-table](https://material-table-core.com/)
 *
 * @example
 * <DataTable
 *   title="Users"
 *   options={{search: false, paging: false}}
 *   columns={[{title: 'Name', field: 'name'}]}
 *   loadData={(query) => apiCallForUsers(query)}
 *   onRowClick={(user) => console.log(user)}
 *   canRefresh={false}
 *   actions={{
 *     before: [{
 *       icon: 'link',
 *       tooltip: 'Link Existing User',
 *       isFreeAction: true,
 *       onClick: () => linkUser()
 *     }],
 *     after: []
 *   }}
 *   newForm={{
 *     title: 'Add New User',
 *     render: (props) => <UserForm user={{}} {...props}/>,
 *     onSaved: (saved) => console.log(saved)
 *   }}
 *   editForm={{
 *     render: (user, props) => <UserForm user={user} {...props}/>
 *   }}
 *   tableProps={{isLoading: true}}
 * />
 *
 */

const DataTable = forwardRef((
  {
    title,
    options = {},
    components = {},
    columns = [],
    loadData,
    data,
    onRowClick,
    canRefresh = true,
    actions: additionalActions,
    newForm,
    editForm,
    tableProps
  }, ref
) => {
  /** @type {({current: MaterialTable})} */
  const tableRef = useRef();
  /** @type {({current: BaseForm})} */
  const formRef = useRef();
  const firstLoad = useRef(true);
  const [errorOpen, setErrorOpen] = useState(false);
  const [newOpen, setNewOpen] = useState(false);
  const [actions, setActions] = useState([]);

  const refresh = () => tableRef.current && tableRef.current.onQueryChange(null);

  useImperativeHandle(ref, () => ({
    refresh() {
      refresh();
    }
  }));

  useEffect(() => {
    if (!firstLoad.current && tableRef.current) {
      refresh();
    }
    firstLoad.current = false;
  }, []);

  useEffect(() => {
    const list = [];
    if (canRefresh) {
      list.push({
        icon: 'refresh',
        tooltip: 'Refresh Data',
        isFreeAction: true,
        onClick: refresh,
      });
    }
    if (newForm) {
      list.push({
        icon: 'add',
        tooltip: newForm.title,
        isFreeAction: true,
        onClick: () => setNewOpen(true)
      });
    }
    setActions([
      ...(additionalActions?.before ?? []),
      ...list,
      ...(additionalActions?.after ?? [])
    ]);
  }, [canRefresh, newForm, additionalActions]);

  const handleSave = () => {
    formRef.current.save();
  };

  const handleCreated = useCallback((saved) => {
    setNewOpen(false);
    if (newForm.onSaved) {
      newForm.onSaved(saved, refresh)
    } else {
      refresh();
    }
  }, [newForm]);

  const handleUpdated = useCallback((saved) => {
    if (editForm.onSaved) {
      editForm.onSaved(saved)
    } else {
      refresh();
    }
  }, [editForm]);

  return (
    <>
      <MaterialTable
        title={title}
        tableRef={tableRef}
        options={{
          debounceInterval: 500,
          detailPanelType: 'single',
          emptyRowsWhenPaging: false,
          pageSize: 50,
          pageSizeOptions: [50, 100, 250],
          search: true,
          ...options
        }}
        components={{
          Container: props => <Paper elevation={0} {...props} />,
          Toolbar: props => <MTableToolbar {...props} />,
          ...components
        }}
        columns={columns}
        data={data ? data : query => new Promise(resolve => {
          loadData(query)
            .then(response => resolve(response))
            .catch(() => {
              setErrorOpen(true);
              resolve({data: [], page: 0, totalCount: 0});
            })
        })}
        onRowClick={(event, rowData, toggleDetailPanel) => {
          if (onRowClick) {
            onRowClick(rowData)
          } else if (editForm) {
            toggleDetailPanel()
          }
        }}
        detailPanel={editForm ? [
          {
            render: ({rowData}) => {
              return (
                <Box paddingX={2}>
                  {editForm.render(rowData, {ref: formRef, onSaved: handleUpdated})}
                  <Box paddingY={2}>
                    <Grid container={true} alignItems="center" justifyContent="center">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Box>
                </Box>
              )
            }
          }
        ] : []}
        actions={actions}
        {...tableProps}
      />
      <Snackbar open={errorOpen} autoHideDuration={4000} onClose={() => setErrorOpen(false)}>
        <Alert onClose={() => setErrorOpen(false)} severity="error">
          There was a problem retrieving the data
        </Alert>
      </Snackbar>

      {newForm ? (<Dialog open={newOpen} onClose={() => setNewOpen(false)} fullWidth maxWidth="md">
        <DialogTitle id="form-dialog-title">{newForm.title}</DialogTitle>
        <DialogContent>
          {newForm.render({ref: formRef, onSaved: handleCreated})}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNewOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>) : null}
    </>
  );
});

DataTable.propTypes = {
  title: PropTypes.string,
  options: PropTypes.object,
  components: PropTypes.object,
  columns: PropTypes.array,
  loadData: PropTypes.func,
  data: PropTypes.array,
  onRowClick: PropTypes.func,
  canRefresh: PropTypes.bool,
  actions: PropTypes.object,
  newForm: PropTypes.object,
  editForm: PropTypes.object,
  tableProps: PropTypes.object
}

export default DataTable;
