import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {Tooltip} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React from 'react';
import {isFunction} from '../../utilities';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },

  cell: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },

  handle: {
    cursor: 'pointer'
  },

  attribute: {
    borderLeft: `1px solid ${theme.palette.grey['300']}`,
    flex: 1,
    justifyContent: 'flex-start'
  },

  button: {
    borderLeft: `1px solid ${theme.palette.grey['300']}`,
    cursor: 'pointer'
  }
}));

const Item = ({entity, attributes = [], controls, handleProps}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={[classes.cell, classes.handle].join(' ')} {...handleProps}><DragIndicatorIcon/></div>
      {attributes.map((attribute, index) => (
        <div key={index} className={[classes.cell, classes.attribute].join(' ')}>{attribute}</div>
      ))}
      {controls.filter(({shouldDisplay = () => true}) => shouldDisplay(entity)).map(
        ({title, icon, onClick = () => null}, index) => (
          <div key={index} className={[classes.cell, classes.button].join(' ')} onClick={() => onClick(entity)}>
            <Tooltip title={isFunction(title) ? title(entity) : title}>{isFunction(icon) ? icon(entity) : icon}</Tooltip>
          </div>
        ))}
    </div>
  );
};

export default Item;
