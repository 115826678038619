import React, {createContext} from 'react';
import {AttributeService} from '../services/AttributeService';
import {AuthService} from '../services/AuthService';
import {ClientService} from '../services/ClientService';
import {JobService} from '../services/JobService';
import {NotificationService} from '../services/NotificationService';
import {ServiceProvider} from '../services/ServiceProvider';
import {SettingsService} from '../services/SettingsService';
import {UserService} from '../services/UserService';
import {VisitService} from '../services/VisitService';

export const ServiceContext = createContext({});

export default function Services({children}) {
  const services = {
    attribute: {
      appointmentOutcome: new AttributeService('appointment-outcomes'),
      environment: new AttributeService('environments'),
      furnitureCondition: new AttributeService('furniture-conditions'),
      jobType: new AttributeService('job-types'),
      maintenance: new AttributeService('maintenances'),
    },
    auth: new AuthService(),
    client: new ClientService(),
    job: new JobService(),
    notification: new NotificationService(),
    settings: new SettingsService(),
    user: new UserService(),
    visit: new VisitService()
  };
  ServiceProvider.setServices(services);
  return (
    <ServiceContext.Provider value={services}>{children}</ServiceContext.Provider>
  );
}
