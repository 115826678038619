import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const extractFields = (
  {
    manufacturer,
    model,
    material,
    retailer,
    colour,
    configuration,
    issue,
    age,
    parts_from_client,
    parts_from_supplier
  }
) => ({
  manufacturer,
  model,
  material,
  retailer,
  colour,
  configuration,
  issue,
  age,
  parts_from_client,
  parts_from_supplier
});

const JobInformationForm = forwardRef(({job, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    },

    getData() {
      const data = formRef.current.getData();
      const prefix = `job${job.id ?? ''}__`;
      const noPrefix = {};
      for (let key of Object.keys(data)) {
        noPrefix[key.replace(prefix, '')] = data[key];
      }
      return extractFields(noPrefix);
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={job}
      type="job"
      fieldsComponent={(props) => <Fields job={job} {...props}/>}
      onSave={({id, ...data}) => services.job.saveJob({
        id,
        ...extractFields(data)
      })}
      onSaved={onSaved}
    />
  );
});

export default JobInformationForm;
