import {DataTable, date, SquareButton} from '@management-ui/core';
import {Add, CalendarToday} from '@mui/icons-material';
import {Grid} from '@mui/material';
import moment from 'moment';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';
import VisitStatus from '../../../../components/VisitStatus';
import routes from '../../../../routes';

const Table = (
  {
    title = 'Visits',
    job = null,
    client = null,
    range = null,
    tableProps = {},
    onDataUpdated = () => null
  }) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  /** @type {({current: DataTable})} */
  const tableRef = useRef();
  const initialised = useRef(false);
  useEffect(() => {
    if (!initialised.current) {
      initialised.current = true;
    } else {
      tableRef.current.refresh();
    }
  }, [range]);

  const goToDetail = useCallback((visit) => {
    history.push(reverse(routes.jobs.visit.detail, {id: visit.job.id, visitID: visit.id}));
  }, [history]);

  return (
    <DataTable
      title={title}
      ref={tableRef}
      actions={useMemo(() => job ? {
        before: [],
        after: job.completed ? [] : [{
          icon: () => <Add/>,
          tooltip: 'Add New Visit',
          isFreeAction: true,
          onClick: () => history.push(reverse(routes.jobs.newVisit, {id: job.id}))
        }]
      } : (client ? null : {
        before: [],
        after: [{
          icon: () => <CalendarToday/>,
          tooltip: 'View Calendar',
          isFreeAction: true,
          onClick: () => history.push(routes.visits.index)
        }]
      }), [job, client, history])}
      columns={useMemo(() => [
        {title: 'Job Reference', field: 'reference', render: visit => visit.job.reference},
        ...(client ? [] : [{title: 'Client', field: 'client', render: visit => visit.job.client.name}]),
        {title: 'Type', field: 'type', render: visit => visit.job.type.name},
        {title: 'Customer', field: 'customer', render: visit => visit.job.customer},
        {title: 'Date', field: 'date', render: visit => visit.date ? date(visit.date) : '-'},
        {title: 'Time', field: 'time', render: visit => visit.time === 'Other' ? (visit.special_requirements ?? 'Other') : visit.time},
        {title: 'Units', field: 'units', render: visit => visit.units ? `${visit.units} unit${visit.units === 1 ? '' : 's'}` : '-'},
        {title: 'Status', field: 'status', sorting: false, render: visit => <VisitStatus visit={visit}/>},
        ...(client ? [{
          title: 'Total Cost',
          field: 'total_cost',
          sorting: false,
          render: visit => <Currency amount={visit['total_cost']}/>
        }] : []),
        {
          title: '',
          field: 'calendar',
          sorting: false,
          width: 120,
          headerStyle: {
            textAlign: 'center',
          },
          cellStyle: {
            textAlign: 'center',
          },
          render: (item) => item.confirmed ? (
            <Grid container alignItems="center" justifyContent="center" onClick={(e) => e.stopPropagation()}>
              <SquareButton
                tooltip="View Calendar"
                icon={<CalendarToday/>}
                onClick={() => history.push(`${routes.visits.index}?week=${moment(item.date).startOf('isoWeek').format('YYYY-MM-DD')}`)}
              />
            </Grid>
          ) : null
        }
      ], [history, client])}
      loadData={useCallback(query => new Promise((resolve, reject) => {
        services.visit.getTableVisits(
          query,
          {
            ...(job ? {job: job.id} : {}),
            ...(client ? {client: client.id} : {}),
            ...(range ? {range: `${range.from.format('YYYY-MM-DD')}|${range.to.format('YYYY-MM-DD')}`} : {})
          })
          .then(response => {
            onDataUpdated(response.data);
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => reject());
      }), [services, job, client, range, onDataUpdated])}
      onRowClick={goToDetail}
      {...tableProps}
    />
  );
};

export default Table;
