import {ConfirmationDialog, dateTime, DetailColumns, DetailPane, FormDialog, useDialogs} from '@management-ui/core';
import {Delete, Download, Send, Settings} from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import JobStatus from '../../../../components/JobStatus';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import JobDetailsForm from '../../forms/JobDetailsForm';
import Tabs from './Tabs';
import withJob from './withJob';
import Wrapper from './Wrapper';

const Job = ({job, setJob, loadJob, title, crumbs, loading, onLoading, errors}) => {
  const services = useContext(ServiceContext);
  const {openDialogs, toggleDialog} = useDialogs(['editDetails', 'editInformation', 'send', 'reOpen', 'delete']);
  const history = useHistory();

  const handleSaved = useCallback((updated) => {
    toggleDialog('editDetails', false);
    toggleDialog('editInformation', false);
    if (updated) {
      setJob(updated);
    } else if (job?.id) {
      loadJob(job.id);
    }
  }, [toggleDialog, setJob, loadJob, job]);

  const handleSend = useCallback((confirmed) => {
    toggleDialog('send', false);
    if (confirmed) {
      onLoading(true);
      services.job.complete(job)
        .then((completed) => onLoading(false) || setJob(completed))
        .catch(() => onLoading(false));
    }
  }, [services, toggleDialog, job, onLoading, setJob]);

  const handleDelete = useCallback((confirmed) => {
    toggleDialog('delete', false);
    if (confirmed) {
      onLoading(true);
      services.job.deleteJob(job)
        .then(() => {
          onLoading(false);
          history.push(routes.jobs.index);
        })
        .catch(() => onLoading(false));
    }
  }, [services, toggleDialog, job, onLoading, history]);

  const handleReport = useCallback(() => {
    onLoading(true);
    services.job.report(job).then(() => onLoading(false)).catch(() => onLoading(false));
  }, [onLoading, services, job]);

  const handleReOpen = useCallback((confirmed) => {
    toggleDialog('reOpen', false);
    if (confirmed) {
      onLoading(true);
      services.job.reOpen(job)
        .then((opened) => {
          onLoading(false);
          setJob(opened);
        })
        .catch(() => onLoading(false));
    }
  }, [onLoading, services, toggleDialog, job, setJob]);

  return (
    <Wrapper title={title} job={job} loading={loading} crumbs={crumbs} errors={errors}>
      {job?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Core Details"
              actions={[
                {title: 'Download Report', icon: <Download/>, onClick: handleReport},
                ...(job.completed ? [
                  {title: 'Re-open', icon: <Settings/>, onClick: () => toggleDialog('reOpen', true)}
                ] : [
                  {title: 'Edit Job', icon: <EditIcon/>, onClick: () => toggleDialog('editDetails', true)},
                  {
                    title: job['can_complete'] ? 'Mark Job Complete' : 'Cannot mark as complete as there are currently ongoing visits',
                    icon: <Send style={{opacity: job['can_complete'] ? 1 : 0.5}}/>,
                    onClick: () => job['can_complete'] ? toggleDialog('send', true) : null
                  },
                ]),
                {title: 'Delete Job', icon: <Delete/>, onClick: () => toggleDialog('delete', true)}
              ]}
              details={[
                {title: 'Reference', value: job.reference},
                {
                  title: 'Client',
                  value: job.client?.name ?? '-',
                  route: job.client ? reverse(routes.clients.detail, {id: job.client.id}) : null
                },
                {title: 'Type', value: job.type?.name ?? '-'},
                {title: 'Customer Name', value: job.customer ?? '-'},
                {
                  title: 'Address', value: job.address || job.postcode ? (
                    <>
                      {job.address}
                      {job.postcode ? <><br/>{job.postcode}</> : ''}
                    </>
                  ) : '-'
                },
                {title: 'Telephone Number', value: job.phone ?? '-'},
                {title: 'Email Address', value: job.email ?? '-'},
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit Job"
                    open={openDialogs['editDetails'] ?? false}
                    onClose={() => toggleDialog('editDetails', false)}
                    render={(props) => (
                      <JobDetailsForm
                        {...props}
                        job={job}
                        onSaved={handleSaved}/>
                    )}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    title="Mark Job Complete"
                    message={`Are you sure you want to confirm this job is complete? ${job.client.email ? 'Doing so will trigger an email to the client' : ''}`}
                    open={openDialogs['send'] ?? false}
                    onClose={handleSend}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    title="Re-open"
                    message="Are you sure you want to re-open this job?"
                    open={openDialogs['reOpen'] ?? false}
                    onClose={handleReOpen}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    title="Delete Job"
                    message="Are you sure you want to delete this job?"
                    open={openDialogs['delete'] ?? false}
                    onClose={handleDelete}
                  />
                )
              ]}
            />,

            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Status', value: <JobStatus job={job}/>},
                {title: 'Created', value: dateTime(job.created_at)},
                {title: 'Last Updated', value: dateTime(job.updated_at)},
                {title: 'Completed', value: job.completed_at ? dateTime(job.completed_at) : '-'}
              ]}
            />,
          ]}/>

          <Tabs
            job={job}
            onJobUpdated={handleSaved}
          />

        </>
      ) : null}
    </Wrapper>
  );
};

export default withJob(Job);
