import {Checkbox, Panes, Select, TextField} from '@management-ui/core';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';

const AppointmentFields = ({visit = {}, prefix = ''}) => {
  const services = useContext(ServiceContext);
  const [environments, setEnvironments] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [outcomes, setOutcomes] = useState([]);

  useEffect(() => {
    services.attribute.environment.getAttributes()
      .then((list) => setEnvironments(list.map(({id, name}) => ({title: name, value: id}))))
      .catch(() => setEnvironments([]));

    services.attribute.furnitureCondition.getAttributes()
      .then((list) => setConditions(list.map(({id, name}) => ({title: name, value: id}))))
      .catch(() => setConditions([]));

    services.attribute.appointmentOutcome.getAttributes()
      .then((list) => setOutcomes(list.map(({id, name}) => ({title: name, value: id}))))
      .catch(() => setOutcomes([]));
  }, [services]);

  const handleReset = useCallback((subject) => {
    if (subject.environment) {
      subject.environment_id = subject.environment.id;
    }
    if (subject.condition) {
      subject.condition_id = subject.condition.id;
    }
    if (subject.outcome) {
      subject.outcome_id = subject.outcome.id;
    }
    return subject;
  }, []);

  return (
    <Panes
      entity={visit}
      prefix={prefix}
      onReset={handleReset}
      panes={[
        {
          title: '',
          fields: [
            <TextField
              name="batch_number"
              prefix={prefix}
              label="ID/Batch No."
            />,
            <Select
              name="environment_id"
              prefix={prefix}
              label="Liability"
              options={environments}
            />,
            <Select
              name="condition_id"
              prefix={prefix}
              label="Condition of Furniture"
              options={conditions}
            />,
            <Checkbox
              name="repairs_completed"
              prefix={prefix}
              label="Repairs Completed?"
            />,
            <Select
              name="outcome_id"
              prefix={prefix}
              label="Appointment Outcome"
              options={outcomes}
            />,
            <TextField
              name="time_taken"
              prefix={prefix}
              label="Time Taken"
            />,
            <Checkbox
              name="signed_off"
              prefix={prefix}
              label="Customer Satisfied With Repair?"
            />,
            <Checkbox
              name="sample_obtained"
              prefix={prefix}
              label="Sample Obtained?"
            />,
          ]
        }, {
          title: '',
          fields: []
        }
      ]}
      title={''}
    />
  );
}

export default AppointmentFields;
