import {Breadcrumbs, DataTable} from '@management-ui/core';
import {MTableToolbar} from '@material-table/core';
import {CalendarToday} from '@mui/icons-material';
import {Paper} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import JobStatus from '../../../../components/JobStatus';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import JobDetailsForm from '../../forms/JobDetailsForm';
import Filter from './Filter';

const Jobs = () => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const [status, setStatus] = useState('');
  /** @type {({current: DataTable})} */
  const tableRef = useRef();

  const goToDetail = useCallback((job) => {
    history.push(reverse(routes.jobs.detail, {id: job.id}));
  }, [history]);

  const handleStatus = useCallback((changed) => {
    setStatus(changed);
    if (tableRef.current) {
      tableRef.current.refresh();
    }
  }, []);

  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Jobs'}]}/>
      <Paper>
        <DataTable
          ref={tableRef}
          title="Jobs"
          tableProps={{
            components: {
              Toolbar: useCallback((props) => (
                <>
                  <MTableToolbar {...props}/>
                  <Filter
                    status={status}
                    onChangeStatus={handleStatus}
                  />
                </>
              ), [status, handleStatus])
            }
          }}
          actions={useMemo(() => ({
            before: [],
            after: [{
              icon: () => <CalendarToday/>,
              tooltip: 'Bookings Calendar',
              isFreeAction: true,
              onClick: () => history.push(routes.visits.index)
            }]
          }), [history])}
          columns={useMemo(() => [
            {title: 'Reference', field: 'reference'},
            {title: 'Client', field: 'client', render: job => job.client.name},
            {title: 'Type', field: 'type', render: job => job.type.name},
            {title: 'Customer', field: 'customer'},
            {title: 'Status', field: 'status', sorting: false, render: job => <JobStatus job={job}/>},
          ], [])}
          loadData={query => new Promise((resolve, reject) => {
            const params = {};
            if (status === 'ongoing') {
              params.ongoing = '1';
            } else if (status === 'complete') {
              params.completed = '1';
            }
            services.job.getTableJobs(query, params)
              .then(response => {
                resolve({
                  data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
                });
              }).catch(() => reject());
          })}
          newForm={{
            title: 'Add New Job',
            render: (props) => <JobDetailsForm job={{}} {...props}/>,
            onSaved: goToDetail
          }}
          onRowClick={goToDetail}
        />
      </Paper>
    </div>
  );
};

export default Jobs;
