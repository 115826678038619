import {
  ConfirmationDialog,
  date,
  dateTime,
  DetailColumns,
  DetailPane,
  FormDialog,
  name,
  useDialogs,
  useErrors
} from '@management-ui/core';
import {CalendarToday, Check, Delete, Download, Send, Settings} from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useRouteMatch} from 'react-router';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../../components/Services';
import VisitStatus from '../../../../../components/VisitStatus';
import routes from '../../../../../routes';
import VisitForm from '../../../forms/VisitForm';
import Wrapper from '../Wrapper';
import Tabs from './Tabs';

const Visit = () => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const match = useRouteMatch();

  const {errors, toggleError} = useErrors(
    useMemo(() => ({job: 'Sorry the visit could not be accessed'}), [])
  );

  const {openDialogs, toggleDialog} = useDialogs(['complete', 'reOpen', 'editDetails', 'send', 'delete']);

  const idRef = useRef('');
  const [loading, setLoading] = useState(false);
  const [visit, setVisit] = useState(null);

  const loadVisit = useCallback((id) => {
    if (idRef.current !== `${id}`) {
      idRef.current = `${id}`;
      setLoading(true);
      services.visit.getVisit(id).then(retrieved => {
        setVisit(retrieved);
        setLoading(false);
      }).catch(() => {
        toggleError('visit', true);
        setLoading(false);
      });
    }
  }, [services, toggleError]);

  useEffect(() => {
    if (match.params?.visitID) {
      loadVisit(match.params?.visitID);
    }
  }, [history, match, visit, loadVisit]);

  const [title, setTitle] = useState('Loading');
  const [crumbs, setCrumbs] = useState([...Wrapper.standardCrumbs]);

  useEffect(() => {
    let header = 'Loading';
    let trail = [...Wrapper.standardCrumbs];
    if (visit?.job) {
      trail.push({title: visit.job.reference, link: reverse(routes.jobs.detail, {id: visit.job.id})});
      trail.push({title: 'Visits', link: reverse(routes.jobs.tab, {id: visit.job.id, tab: 'visits'})});
      const when = `${date(visit.date)} ${visit.time}`;
      header = `Visit Details - ${visit.job.reference} - ${when}`;
      trail.push({title: when, link: ''});
    }
    setTitle(header);
    setCrumbs(trail);
  }, [visit]);

  const handleSaved = useCallback((updated) => {
    toggleDialog('editDetails', false);
    if (updated) {
      setVisit(updated);
    } else if (visit?.id) {
      loadVisit(visit.id);
    }
  }, [toggleDialog, setVisit, loadVisit, visit]);

  const handleSend = useCallback((confirmed) => {
    toggleDialog('send', false);
    if (confirmed) {
      setLoading(true);
      services.visit.sendConfirmation(visit)
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
  }, [services, toggleDialog, visit]);

  const handleComplete = useCallback((confirmed) => {
    toggleDialog('complete', false);
    if (confirmed) {
      setLoading(true);
      services.visit.complete(visit)
        .then((completed) => {
          setLoading(false);
          setVisit(completed);
        })
        .catch(() => setLoading(false));
    }
  }, [services, toggleDialog, visit, setVisit]);

  const handleReOpen = useCallback((confirmed) => {
    toggleDialog('reOpen', false);
    if (confirmed) {
      setLoading(true);
      services.visit.reOpen(visit)
        .then((opened) => {
          setLoading(false);
          setVisit(opened);
        })
        .catch(() => setLoading(false));
    }
  }, [services, toggleDialog, visit, setVisit]);

  const handleDelete = useCallback((confirmed) => {
    toggleDialog('delete', false);
    if (confirmed) {
      setLoading(true);
      services.visit.deleteVisit(visit).then(() => {
        setLoading(false);
        history.push(reverse(routes.jobs.detail, {id: visit.job.id}));
      }).catch(() => setLoading(false));
    }
  }, [services, history, toggleDialog, visit]);

  const handleReport = useCallback(() => {
    setLoading(true);
    services.visit.report(visit).then(() => setLoading(false)).catch(() => setLoading(false));
  }, [services, visit]);

  return (
    <Wrapper title={title} loading={loading} crumbs={crumbs} errors={errors}>
      {visit?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Visit Details"
              actions={[
                {title: 'Download Report', icon: <Download/>, onClick: handleReport},
                ...(!visit['completed_at'] ? [
                  {title: 'Mark as Complete', icon: <Check/>, onClick: () => toggleDialog('complete', true)}
                ] : [
                  {title: 'Re-open', icon: <Settings/>, onClick: () => toggleDialog('reOpen', true)}
                ]),
                {title: 'Edit Visit', icon: <EditIcon/>, onClick: () => toggleDialog('editDetails', true)},
                ...(visit.job.email ? [
                  {title: 'Send Confirmation', icon: <Send/>, onClick: () => toggleDialog('send', true)}
                ] : []),
                ...(visit.confirmed ? [{
                  title: 'View Calendar',
                  icon: <CalendarToday/>,
                  onClick: () => history.push(`${routes.visits.index}?week=${moment(visit.date).startOf('isoWeek').format('YYYY-MM-DD')}`)
                }] : []),
                {title: 'Delete Visit', icon: <Delete/>, onClick: () => toggleDialog('delete', true)},
              ]}
              details={[
                {
                  title: 'Job',
                  value: visit.job?.reference ?? '-',
                  route: visit.job ? reverse(routes.jobs.detail, {id: visit.job.id}) : null
                },
                {
                  title: 'Technician',
                  value: visit.user ? name(visit.user) : '-'
                },
                {
                  title: 'Date/Time',
                  value: visit.date ? `${date(visit.date)} ${visit.time === 'Other' ? (visit.special_requirements ?? 'Other') : visit.time}` : '-'
                },
                {
                  title: 'Length',
                  value: `${visit['units']} unit${visit['units'] === 1 ? '' : 's'}`
                },
                {
                  title: 'Notes',
                  value: visit.notes ?? '-'
                },
              ]}
              dialogs={[
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    title="Mark as Complete"
                    message="Are you sure you want to mark this visit as complete?"
                    open={openDialogs['complete'] ?? false}
                    onClose={handleComplete}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    title="Re-open"
                    message="Are you sure you want to re-open this visit?"
                    open={openDialogs['reOpen'] ?? false}
                    onClose={handleReOpen}
                  />
                ),
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit Visit"
                    open={openDialogs['editDetails'] ?? false}
                    onClose={() => toggleDialog('editDetails', false)}
                    render={(props) => (
                      <VisitForm
                        {...props}
                        job={visit.job}
                        visit={visit}
                        onSaved={handleSaved}
                      />
                    )}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    title="Send Confirmation"
                    message="Are you sure you want to send a confirmation for this visit?"
                    open={openDialogs['send'] ?? false}
                    onClose={handleSend}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    title="Delete Visit"
                    message="Are you sure you want to delete this visit?"
                    open={openDialogs['delete'] ?? false}
                    onClose={handleDelete}
                  />
                )
              ]}
            />,

            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Status', value: <VisitStatus visit={visit}/>},
                {title: 'Created', value: dateTime(visit.created_at)},
                {title: 'Last Updated', value: dateTime(visit.updated_at)},
                ...(visit['completed_at'] ? [{title: 'Completed', value: dateTime(visit['completed_at'])}] : [])
              ]}
            />,
          ]}/>

          <Tabs
            visit={visit}
            onVisitUpdated={handleSaved}
          />
        </>
      ) : null}
    </Wrapper>
  );
};

export default Visit;
