import {Panes, TextField} from '@management-ui/core';
import React from 'react';

const Fields = ({part = {}, prefix = ''}) => {
  return (
    <Panes
      entity={part}
      prefix={prefix}
      panes={[
        {
          title: 'Part Details',
          fields: [
            <TextField
              name="name"
              prefix={prefix}
              label="Name"
              rules={{required: 'Please enter a name for this part'}}
            />,
            <TextField
              name="quantity"
              prefix={prefix}
              label="Quantity"
              rules={{required: 'Please enter a quantity'}}
            />,
          ]
        }
      ]}
      title=""/>
  );
};

export default Fields;
